import React from 'react';
import { ReactNode } from 'react';
import './ButtonGroup.scss';

type Props = {
  children: ReactNode;
}

export const ButtonGroup = ({ children }: Props) => (
  <div className="flex flex-row overflow-x-auto whitespace-nowrap btn-group">
    {children}
  </div>
);

import React from 'react';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/button-group/ButtonGroup';
import { Header } from '../../../components/typography/Header';
import { useFormat } from '../../../hooks/useFormat';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';

export const FormatDetails = () => {
  const { formatId } = useParams();
  const { format } = useFormat(formatId);
  const navigate = useNavigate();

  useKeyboardShortcuts({
    onCancel: () => navigate(-1),
    onEditItem: () => navigate(`/settings/formats/${formatId}/edit`),
    onDeleteItem: () => navigate(`/settings/formats/${formatId}/delete`),
  });

  return (
    <div>
      <Header>{format?.name || 'Formaat'}</Header>

      <ButtonGroup>
        <Button onClick={() => navigate(-1)}>Terug</Button>
        <Button onClick={() => navigate(`/settings/formats/${formatId}/edit`)}>Aanpassen</Button>
        <Button onClick={() => navigate(`/settings/formats/${formatId}/delete`)}>Verwijderen</Button>
      </ButtonGroup>

      <table className="mt-4">
        <tbody>
          <tr>
            <th>Aangemaakt</th>
            <td>{dayjs(format?.created_at).fromNow()} op {dayjs(format?.created_at).format('DD-MM-YYYY HH:mm:ss')}</td>
          </tr>
          <tr>
            <th>Aangepast</th>
            <td>{dayjs(format?.updated_at).fromNow()} op {dayjs(format?.updated_at).format('DD-MM-YYYY HH:mm:ss')}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { StatusForm } from '../../../components/statuses/StatusForm';
import { Header } from '../../../components/typography/Header';
import { storeStatus } from '../../../features/status/statusAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { useStatus } from '../../../hooks/useStatus';
import { Status } from '../../../types/status';

export const StatusEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { statusId } = useParams();
  const { status } = useStatus(statusId);

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (status: Status) => {
    dispatch(storeStatus(status));
    navigate(`/settings/statuses/${statusId}`);
  }, [dispatch, navigate, statusId]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Status aanpassen</Header>
      {status && <StatusForm onSave={onSave} onCancel={onCancel} status={status} />}
    </div>
  );
};

import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Column, Table } from '../../../components/table/Table';
import { Header } from '../../../components/typography/Header';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { useUnits } from '../../../hooks/useUnits';
import { Unit } from '../../../types/unit';

const getDate = (unit: Unit, key: keyof Unit) => dayjs(unit[key]).format('DD-MM-YYYY HH:mm:ss');

const columns: Column<Unit>[] = [
  { label: 'Naam', getValue: (unit: Unit) => unit.name || '-'},
  { label: 'Symbool', getValue: (unit: Unit) => unit.symbol || '-'},
  { label: 'Aangemaakt op', getValue: (unit) => getDate(unit, 'created_at') },
  { label: 'Aangepast op', getValue: (unit) => getDate(unit, 'updated_at') },
];

export const UnitSettings = () => {
  const units = useUnits();
  const navigate = useNavigate();

  useKeyboardShortcuts({
    onCreateItem: () => navigate('create'),
  });

  return (
    <div>
      <Header>Eenheiden ({units?.length || 0})</Header>
      <Button onClick={() => navigate('create')} className="mb-4">Eenheid toevoegen</Button>

      <Table
        columns={columns}
        data={units}
        striped
        hover
        onRowClick={(unit) => navigate(`/settings/units/${unit.id}`)}
      />
    </div>
  );
};

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchGrade } from '../features/grade/gradeAPI';

export const useGrade = (gradeId: string | undefined) => {
  const dispatch = useAppDispatch();
  const grade = useAppSelector((state) => state.grade.grades?.find((r) => r.id === gradeId));

  useEffect(() => {
    if (!gradeId) {
      return;
    }

    dispatch(fetchGrade(gradeId));
  }, [gradeId, dispatch]);

  return { grade };
};

import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Column, Table } from '../../../components/table/Table';
import { Header } from '../../../components/typography/Header';
import { useFormats } from '../../../hooks/useFormats';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Format } from '../../../types/format';

const getDate = (format: Format, key: keyof Format) => dayjs(format[key]).format('DD-MM-YYYY HH:mm:ss');

const columns: Column<Format>[] = [
  { label: 'Naam', getValue: (format: Format) => format.name || '-'},
  { label: 'Aangemaakt op', getValue: (format) => getDate(format, 'created_at') },
  { label: 'Aangepast op', getValue: (format) => getDate(format, 'updated_at') },
];

export const FormatSettings = () => {
  const formats = useFormats();
  const navigate = useNavigate();

  useKeyboardShortcuts({
    onCreateItem: () => navigate('create'),
  });

  return (
    <div>
      <Header>Formaten ({formats?.length || 0})</Header>
      <Button onClick={() => navigate('create')} className="mb-4">Formaat toevoegen</Button>

      <Table
        columns={columns}
        data={formats}
        striped
        hover
        onRowClick={(format) => navigate(`/settings/formats/${format.id}`)}
      />
    </div>
  );
};

import React from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { RegionForm } from '../../../components/regions/RegionForm';
import { Header } from '../../../components/typography/Header';
import { storeRegion } from '../../../features/region/regionAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Region } from '../../../types/region';

export const RegionCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (region: Region) => {
    dispatch(storeRegion(region));
    navigate('/settings/regions');
  }, [dispatch, navigate]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Regio aanmaken</Header>
      <RegionForm onSave={onSave} onCancel={onCancel} />
    </div>
  );
};

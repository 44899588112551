import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Region } from '../../types/region';

export interface RegionState {
  regions: Region[] | null;
}

const initialState: RegionState = {
  regions: null,
};

export const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    regionsLoaded: (state, action: PayloadAction<Region[]>) => {
      state.regions = action.payload;
    },
    addRegion: (state, action: PayloadAction<Region>) => {
      if (state.regions === null) {
        state.regions = [];
      }
      state.regions.push(action.payload);
    },
    updateRegion: (state, action: PayloadAction<Region>) => {
      const index = state.regions?.findIndex((r) => r.id === action.payload.id);
      if (index === -1 || index === undefined) {
        return;
      }

      state.regions?.splice(index, 1, action.payload);
    },
    removeRegion: (state, action: PayloadAction<string>) => {
      const index = state.regions?.findIndex((r) => r.id === action.payload);
      if (index === -1 || index === undefined) {
        return;
      }

      state.regions?.splice(index, 1);
    },
  },
});

export const selectRegions = (state: RootState) => state.region.regions;
export const selectRegion = (state: RootState, regionId: string) => state.region.regions?.find((r) => r.id === regionId) || null;

export const { regionsLoaded, addRegion, updateRegion, removeRegion } = regionSlice.actions;

export default regionSlice.reducer;

import React from 'react';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Summary = lazy(() => import('../views/summary/Summary'));

export const SummaryRoutes: RouteObject[] = [
  {
    path: 'summary',
    element: <Summary />,
  },
];

import { AppThunk } from '../../app/store';
import apiClient from '../../services/apiClient';
import { Group } from '../../types/group';
import { addErrorMessage, addSuccessMessage } from '../base/baseAPI';
import { appFinishedLoading, appIsLoading } from '../base/baseSlice';
import { addGroup, groupsLoaded, removeGroup, selectGroup, updateGroup } from './groupSlice';

export const fetchGroups = (): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<Group[]>('/groups');
    dispatch(groupsLoaded(response.data));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de groepen.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const fetchGroup = (groupId: string): AppThunk<Promise<Group>> => async (dispatch, getState) => {
  const group = selectGroup(getState(), groupId);
  if (group !== null) {
    return group;
  }

  dispatch(appIsLoading());
  try {
    const response = await apiClient.get<Group>(`/groups/${groupId}`);
    dispatch(addGroup(response.data));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het laden van de groep ${groupId}`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const fetchCoverUrl = (groupId: string): AppThunk<Promise<string>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<Blob>(`/groups/${groupId}/cover`, { responseType: 'blob', timeout: 30000 });
    return URL.createObjectURL(response.data);
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de afbeelding.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const storeGroup = (group: Group): AppThunk<Promise<Group>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    let response;
    if (group.id === undefined) {
      response = await apiClient.post<Group>('/groups', group);
      dispatch(addGroup(response.data));
    } else {
      response = await apiClient.put<Group>(`/groups/${group.id}`, group);
      dispatch(updateGroup(response.data));
    }

    dispatch(addSuccessMessage(`Group ${group.name} is opgeslagen!`));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het opslaan van de groep.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const deleteGroup = (groupId: string): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    await apiClient.delete(`/groups/${groupId}`);
    dispatch(removeGroup(groupId));
    dispatch(addSuccessMessage('De groep is verwijderd.'));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het verwijderen van de groep ${groupId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

import React from 'react';
import { useMemo } from 'react';
import { useGroups } from '../../../hooks/useGroups';
import { Group } from '../../../types/group';
import { convertEntityToOption } from './filter.helper';
import { FilterProps } from './filter.types';
import { FilterSelect } from './FilterSelect';

export function GroupFilter<T extends {group: Group|null}>({ filter, onChange }: FilterProps<T>) {
  const groups = useGroups();
  const groupOptions = useMemo(() => groups?.map(convertEntityToOption) || [], [groups]);
  const selectedGroupOption = useMemo(() => groupOptions.find((group) => group.value === filter.group?.id), [groupOptions, filter]);

  return (
    <FilterSelect
      onChange={(value) => onChange({ ...filter, group: groups?.find((c) => c.id === value?.value) || null })}
      selectedOption={selectedGroupOption}
      options={groupOptions}
      placeholder="Selecteer een groep..."
    />
  );
}

import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Column, Table } from '../../../components/table/Table';
import { Header } from '../../../components/typography/Header';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { useStatuses } from '../../../hooks/useStatuses';
import { Status } from '../../../types/status';

const getDate = (status: Status, key: keyof Status) => dayjs(status[key]).format('DD-MM-YYYY HH:mm:ss');

const columns: Column<Status>[] = [
  { label: 'Naam', getValue: (status: Status) => status.name || '-'},
  { label: 'Aangemaakt op', getValue: (status) => getDate(status, 'created_at') },
  { label: 'Aangepast op', getValue: (status) => getDate(status, 'updated_at') },
];

export const StatusSettings = () => {
  const statuses = useStatuses();
  const navigate = useNavigate();

  useKeyboardShortcuts({
    onCreateItem: () => navigate('create'),
  });

  return (
    <div>
      <Header>Statussen ({statuses?.length || 0})</Header>
      <Button onClick={() => navigate('create')} className="mb-4">Status toevoegen</Button>

      <Table
        columns={columns}
        data={statuses}
        striped
        hover
        onRowClick={(status) => navigate(`/settings/statuses/${status.id}`)}
      />
    </div>
  );
};

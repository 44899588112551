import { useCallback, useState } from 'react';
import { useAppDispatch } from '../app/hooks';
import { fetchPictureUrl, fetchPictureUrlWithoutBackground } from '../features/picture/pictureAPI';
import { Picture } from '../types/picture';

export const usePictureUrl = (picture?: Picture | null, removeBackground = false) => {
  const dispatch = useAppDispatch();
  const [pictureUrl, setPictureUrl] = useState<string | null>(null);

  const loadPicture = useCallback(async () => {
    if (picture?.id === undefined) {
      return;
    }

    setPictureUrl(null);
    if (!removeBackground) {
      setPictureUrl(await dispatch(fetchPictureUrl(picture.id)));
    } else {
      setPictureUrl(await dispatch(fetchPictureUrlWithoutBackground(picture.id)));
    }
  }, [picture, dispatch, removeBackground]);

  return { pictureUrl, loadPicture };
};

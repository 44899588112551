import React from 'react';
import { ChangeEventHandler, useCallback } from 'react';
import { FilterProps } from './filter.types';

export function TagsFilter<T extends {withoutTag: boolean}>({ filter, onChange }: FilterProps<T>) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    onChange({ ...filter, withoutTag: event.target.checked });
  }, [filter, onChange]);

  return (
    <label htmlFor="filter-without-tags">
      <input type="checkbox" id="filter-without-tags" checked={filter.withoutTag} onChange={handleChange} />
      <span className="ml-2">Alleen munten zonder tag laten zien</span>
    </label>
  );
}

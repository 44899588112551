import React from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { StatusForm } from '../../../components/statuses/StatusForm';
import { Header } from '../../../components/typography/Header';
import { storeStatus } from '../../../features/status/statusAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Status } from '../../../types/status';

export const StatusCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (status: Status) => {
    dispatch(storeStatus(status));
    navigate('/settings/statuses');
  }, [dispatch, navigate]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Status aanmaken</Header>
      <StatusForm onSave={onSave} onCancel={onCancel} />
    </div>
  );
};

import React from 'react';
import { useMemo } from 'react';
import { useGrades } from '../../../hooks/useGrades';
import { Grade } from '../../../types/grade';
import { convertEntityToOption } from './filter.helper';
import { FilterProps } from './filter.types';
import { FilterSelect } from './FilterSelect';

export function GradeFilter<T extends {grade: Grade|null}>({ filter, onChange }: FilterProps<T>) {
  const grades = useGrades();
  const gradeOptions = useMemo(() => grades?.map(convertEntityToOption) || [], [grades]);
  const selectedGradeOption = useMemo(() => gradeOptions.find((grade) => grade.value === filter.grade?.id), [gradeOptions, filter]);

  return (
    <FilterSelect
      onChange={(value) => onChange({ ...filter, grade: grades?.find((c) => c.id === value?.value) || null })}
      selectedOption={selectedGradeOption}
      options={gradeOptions}
      placeholder="Selecteer een kwaliteit..."
    />
  );
}

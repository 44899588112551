import React from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { SellerForm } from '../../../components/sellers/SellerForm';
import { Header } from '../../../components/typography/Header';
import { storeSeller } from '../../../features/seller/sellerAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Seller } from '../../../types/seller';

export const SellerCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (seller: Seller) => {
    dispatch(storeSeller(seller));
    navigate('/settings/sellers');
  }, [dispatch, navigate]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Verkoper aanmaken</Header>
      <SellerForm onSave={onSave} onCancel={onCancel} />
    </div>
  );
};

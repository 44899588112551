import React from 'react';
import { useMemo } from 'react';
import { FilterProps, NumericOption } from './filter.types';
import { FilterSelect } from './FilterSelect';

const convertNominalValueToOption = (nominalValue?: number | null): NumericOption => ({ label: (nominalValue || 0) / 100, value: nominalValue || 0 });

export function NominalValueFilter<T extends {nominalValue: number | null}>({ filter, onChange, nominalValues }: FilterProps<T> & { nominalValues: (number|null|undefined)[] }) {
  const nominalValueOptions = useMemo(() => nominalValues.map(convertNominalValueToOption) || [], [nominalValues]);
  const selectedNominalValueOption = useMemo(() => nominalValueOptions.find((nominalValue) => nominalValue.value === filter.nominalValue), [nominalValueOptions, filter]);

  return (
    <FilterSelect
      onChange={(value) => onChange({ ...filter, nominalValue: value?.value || null })}
      selectedOption={selectedNominalValueOption}
      options={nominalValueOptions}
      placeholder="Selecteer een nominale waarde..."
    />
  );
}

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchMaterial } from '../features/material/materialAPI';

export const useMaterial = (materialId: string | undefined) => {
  const dispatch = useAppDispatch();
  const material = useAppSelector((state) => state.material.materials?.find((r) => r.id === materialId));

  useEffect(() => {
    if (!materialId) {
      return;
    }

    dispatch(fetchMaterial(materialId));
  }, [materialId, dispatch]);

  return { material };
};

import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Column, Table } from '../../../components/table/Table';
import { Header } from '../../../components/typography/Header';
import { useGrades } from '../../../hooks/useGrades';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Grade } from '../../../types/grade';

const getDate = (grade: Grade, key: keyof Grade) => dayjs(grade[key]).format('DD-MM-YYYY HH:mm:ss');

const columns: Column<Grade>[] = [
  { label: 'Naam', getValue: (grade: Grade) => grade.name || '-'},
  { label: 'Aangemaakt op', getValue: (grade) => getDate(grade, 'created_at') },
  { label: 'Aangepast op', getValue: (grade) => getDate(grade, 'updated_at') },
];

export const GradeSettings = () => {
  const grades = useGrades();
  const navigate = useNavigate();

  useKeyboardShortcuts({
    onCreateItem: () => navigate('create'),
  });

  return (
    <div>
      <Header>Kwaliteiten ({grades?.length || 0})</Header>
      <Button onClick={() => navigate('create')} className="mb-4">Kwaliteit toevoegen</Button>

      <Table
        columns={columns}
        data={grades}
        striped
        hover
        onRowClick={(grade) => navigate(`/settings/grades/${grade.id}`)}
      />
    </div>
  );
};

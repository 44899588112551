import React from 'react';
import { useMemo } from 'react';
import { useFormats } from '../../../hooks/useFormats';
import { Format } from '../../../types/format';
import { convertEntityToOption } from './filter.helper';
import { FilterProps } from './filter.types';
import { FilterSelect } from './FilterSelect';

export function FormatFilter<T extends {format: Format | null}>({ filter, onChange }: FilterProps<T>) {
  const formats = useFormats();
  const formatOptions = useMemo(() => formats?.map(convertEntityToOption) || [], [formats]);
  const selectedFormatOption = useMemo(() => formatOptions.find((format) => format.value === filter.format?.id), [formatOptions, filter]);

  return (
    <FilterSelect
      onChange={(value) => onChange({ ...filter, format: formats?.find((c) => c.id === value?.value) || null })}
      selectedOption={selectedFormatOption}
      options={formatOptions}
      placeholder="Selecteer een formaat..."
    />
  );
}

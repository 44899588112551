import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { CountryForm } from '../../../components/countries/CountryForm';
import { Header } from '../../../components/typography/Header';
import { storeCountry } from '../../../features/country/countryAPI';
import { useCountry } from '../../../hooks/useCountry';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Country } from '../../../types/country';

export const CountryEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { countryId } = useParams();
  const { country } = useCountry(countryId);

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (country: Country) => {
    dispatch(storeCountry(country));
    navigate(`/settings/countries/${countryId}`);
  }, [dispatch, navigate, countryId]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Land aanpassen</Header>
      {country && <CountryForm onSave={onSave} onCancel={onCancel} country={country} />}
    </div>
  );
};

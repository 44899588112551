import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Status } from '../../types/status';

export interface StatusState {
  statuses: Status[] | null;
}

const initialState: StatusState = {
  statuses: null,
};

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    statusesLoaded: (state, action: PayloadAction<Status[]>) => {
      state.statuses = action.payload;
    },
    addStatus: (state, action: PayloadAction<Status>) => {
      if (state.statuses === null) {
        state.statuses = [];
      }
      state.statuses.push(action.payload);
    },
    updateStatus: (state, action: PayloadAction<Status>) => {
      const index = state.statuses?.findIndex((r) => r.id === action.payload.id);
      if (index === -1 || index === undefined) {
        return;
      }

      state.statuses?.splice(index, 1, action.payload);
    },
    removeStatus: (state, action: PayloadAction<string>) => {
      const index = state.statuses?.findIndex((r) => r.id === action.payload);
      if (index === -1 || index === undefined) {
        return;
      }

      state.statuses?.splice(index, 1);
    },
  },
});

export const selectStatuses = (state: RootState) => state.status.statuses;
export const selectStatus = (state: RootState, statusId: string) => state.status.statuses?.find((r) => r.id === statusId) || null;

export const { statusesLoaded, addStatus, updateStatus, removeStatus } = statusSlice.actions;

export default statusSlice.reducer;

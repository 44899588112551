import React from 'react';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/button-group/ButtonGroup';
import { Header } from '../../../components/typography/Header';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { useUnit } from '../../../hooks/useUnit';

export const UnitDetails = () => {
  const { unitId } = useParams();
  const { unit } = useUnit(unitId);
  const navigate = useNavigate();

  useKeyboardShortcuts({
    onCancel: () => navigate(-1),
    onEditItem: () => navigate(`/settings/units/${unitId}/edit`),
    onDeleteItem: () => navigate(`/settings/units/${unitId}/delete`),
  });

  return (
    <div>
      <Header>{unit?.name || 'Eenheid'}</Header>

      <ButtonGroup>
        <Button onClick={() => navigate(-1)}>Terug</Button>
        <Button onClick={() => navigate(`/settings/units/${unitId}/edit`)}>Aanpassen</Button>
        <Button onClick={() => navigate(`/settings/units/${unitId}/delete`)}>Verwijderen</Button>
      </ButtonGroup>

      <table className="mt-4">
        <tbody>
          <tr>
            <th>Aangemaakt</th>
            <td>{dayjs(unit?.created_at).fromNow()} op {dayjs(unit?.created_at).format('DD-MM-YYYY HH:mm:ss')}</td>
          </tr>
          <tr>
            <th>Aangepast</th>
            <td>{dayjs(unit?.updated_at).fromNow()} op {dayjs(unit?.updated_at).format('DD-MM-YYYY HH:mm:ss')}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { Alert } from '../../../components/Alert';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/button-group/ButtonGroup';
import { Header } from '../../../components/typography/Header';
import { deleteRegion } from '../../../features/region/regionAPI';
import { useRegion } from '../../../hooks/useRegion';

export const RegionDelete = () => {
  const { regionId } = useParams();
  const { region } = useRegion(regionId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    if (!regionId) {
      return;
    }

    dispatch(deleteRegion(regionId));
    navigate('/settings/regions');
  }, [regionId, dispatch, navigate]);

  return (
    <div>
      <Header>Regio verwijderen</Header>
      <Alert type="error">
        <p>Weet je zeker dat je &apos;{region?.name}&apos; wilt verwijderen?</p>

        <div className="mt-3">
          <ButtonGroup>
            <Button onClick={() => navigate(-1)}>Annuleren</Button>
            <Button onClick={handleDelete}>Verwijderen</Button>
          </ButtonGroup>
        </div>
      </Alert>
    </div>
  );
};

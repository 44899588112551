import { AppThunk } from '../../app/store';
import apiClient from '../../services/apiClient';
import { Region } from '../../types/region';
import { addErrorMessage, addSuccessMessage } from '../base/baseAPI';
import { appFinishedLoading, appIsLoading } from '../base/baseSlice';
import { addRegion, regionsLoaded, removeRegion, selectRegion, updateRegion } from './regionSlice';

export const fetchRegions = (): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<Region[]>('/regions');
    dispatch(regionsLoaded(response.data));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de regio\'s.'));
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const fetchRegion = (regionId: string): AppThunk<Promise<Region>> => async (dispatch, getState) => {
  const region = selectRegion(getState(), regionId);
  if (region !== null) {
    return region;
  }

  dispatch(appIsLoading());
  try {
    const response = await apiClient.get<Region>(`/regions/${regionId}`);
    dispatch(addRegion(response.data));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het laden van de regio ${regionId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const storeRegion = (region: Region): AppThunk<Promise<Region>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    let response;
    if (region.id === undefined) {
      // Store new region
      response = await apiClient.post<Region>('/regions', region);
      dispatch(addRegion(response.data));
    } else {
      // Update existing region
      response = await apiClient.put<Region>(`/regions/${region.id}`, region);
      dispatch(updateRegion(response.data));
    }

    dispatch(addSuccessMessage(`Regio '${region.name}' is opgeslagen!`));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het opslaan van de regio.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const deleteRegion = (regionId: string): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());
  try {
    await apiClient.delete(`/regions/${regionId}`);
    dispatch(removeRegion(regionId));
    dispatch(addSuccessMessage('De regio is verwijderd.'));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het verwijderen van de regio ${regionId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { FormatForm } from '../../../components/formats/FormatForm';
import { Header } from '../../../components/typography/Header';
import { storeFormat } from '../../../features/format/formatAPI';
import { useFormat } from '../../../hooks/useFormat';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Format } from '../../../types/format';

export const FormatEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { formatId } = useParams();
  const { format } = useFormat(formatId);

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (format: Format) => {
    dispatch(storeFormat(format));
    navigate(`/settings/formats/${formatId}`);
  }, [dispatch, navigate, formatId]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Formaat aanpassen</Header>
      {format && <FormatForm onSave={onSave} onCancel={onCancel} format={format} />}
    </div>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
  onClick?: () => void;
  children: string;
  active?: boolean;
}

export const MainMenuButton = ({ to, onClick, children, active = false }: Props) => {
  if (onClick) {
    return (
      <div
        onClick={onClick}
        className={`px-6 lg:px-5 py-3 lg:py-2 block hover:bg-gray-800${active ? ' bg-gray-800' : ''} no-underline`}
      >
        {children}
      </div>
    );
  }

  return (
    <Link
      to={to}
      className={`px-6 lg:px-5 py-3 lg:py-2 block hover:bg-gray-800${active ? ' bg-gray-800' : ''} no-underline`}
    >
      {children}
    </Link>
  );
};

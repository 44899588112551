import { AppThunk } from '../../app/store';
import apiClient from '../../services/apiClient';
import { Material } from '../../types/material';
import { addErrorMessage, addSuccessMessage } from '../base/baseAPI';
import { appFinishedLoading, appIsLoading } from '../base/baseSlice';
import { addMaterial, materialsLoaded, removeMaterial, selectMaterial, updateMaterial } from './materialSlice';

const apiEndpoint = '/materials';

export const fetchMaterials = (): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<Material[]>(apiEndpoint);
    dispatch(materialsLoaded(response.data));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de materialen.'));
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const fetchMaterial = (materialId: string): AppThunk<Promise<Material>> => async (dispatch, getState) => {
  const material = selectMaterial(getState(), materialId);
  if (material !== null) {
    return material;
  }

  dispatch(appIsLoading());
  try {
    const response = await apiClient.get<Material>(`${apiEndpoint}/${materialId}`);
    dispatch(addMaterial(response.data));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het laden van het materiaal ${materialId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const storeMaterial = (material: Material): AppThunk<Promise<Material>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    let response;
    if (material.id === undefined) {
      // Store new material
      response = await apiClient.post<Material>(apiEndpoint, material);
      dispatch(addMaterial(response.data));
    } else {
      // Update existing material
      response = await apiClient.put<Material>(`${apiEndpoint}/${material.id}`, material);
      dispatch(updateMaterial(response.data));
    }

    dispatch(addSuccessMessage(`Materiaal '${material.name}' is opgeslagen!`));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het opslaan van het materiaal.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const deleteMaterial = (materialId: string): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());
  try {
    await apiClient.delete(`${apiEndpoint}/${materialId}`);
    dispatch(removeMaterial(materialId));
    dispatch(addSuccessMessage('Het materiaal is verwijderd.'));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het verwijderen van het materiaal ${materialId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

import 'material-symbols';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { Loader } from './components/Loader';
import { AuthenticationProvider } from './providers/AuthenticationProvider';
import { DayjsProvider } from './providers/DayjsProvider';
import { MessagesProvider } from './providers/MessagesProvider';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { Router } from './Router';

const container = document.getElementById('root');
if (container === null) {
  throw new Error('Cannot find root element');
}

const root = createRoot(container);

const App = () => (
  <Provider store={store}>
    <DayjsProvider>
      <MessagesProvider>
        <AuthenticationProvider>
          <SkeletonTheme baseColor="#394150" highlightColor="#6C727F">
            <Suspense fallback={<Loader />}>
              <Router />
            </Suspense>
          </SkeletonTheme>
        </AuthenticationProvider>
      </MessagesProvider>
    </DayjsProvider>
  </Provider>
);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { SellerForm } from '../../../components/sellers/SellerForm';
import { Header } from '../../../components/typography/Header';
import { storeSeller } from '../../../features/seller/sellerAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { useSeller } from '../../../hooks/useSeller';
import { Seller } from '../../../types/seller';

export const SellerEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { sellerId } = useParams();
  const { seller } = useSeller(sellerId);

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (seller: Seller) => {
    dispatch(storeSeller(seller));
    navigate(`/settings/sellers/${sellerId}`);
  }, [dispatch, navigate, sellerId]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Verkoper aanpassen</Header>
      {seller && <SellerForm onSave={onSave} onCancel={onCancel} seller={seller} />}
    </div>
  );
};

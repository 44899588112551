import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/button-group/ButtonGroup';
import { Header } from '../../../components/typography/Header';
import { useGroup } from '../../../hooks/useGroup';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { usePictureUrl } from '../../../hooks/usePictureUrl';

export const GroupDetails = () => {
  const { groupId } = useParams();
  const { group } = useGroup(groupId);
  const { pictureUrl, loadPicture } = usePictureUrl(group?.cover_image);
  const navigate = useNavigate();

  useEffect(() => {
    loadPicture();
  }, []);

  useKeyboardShortcuts({
    onCancel: () => navigate(-1),
    onEditItem: () => navigate(`/settings/groups/${groupId}/edit`),
    onDeleteItem: () => navigate(`/settings/groups/${groupId}/delete`),
  });

  return (
    <div>
      <Header>{group?.name || 'Groep'}</Header>

      <ButtonGroup>
        <Button onClick={() => navigate(-1)}>Terug</Button>
        <Button onClick={() => navigate(`/settings/groups/${groupId}/edit`)}>Aanpassen</Button>
        <Button onClick={() => navigate(`/settings/groups/${groupId}/delete`)}>Verwijderen</Button>
      </ButtonGroup>

      { pictureUrl && <img src={pictureUrl} className="my-4 max-w-full" alt="" />}

      <table className="mt-4">
        <tbody>
          <tr>
            <th>Aangemaakt</th>
            <td>{dayjs(group?.created_at).fromNow()} op {dayjs(group?.created_at).format('DD-MM-YYYY HH:mm:ss')}</td>
          </tr>
          <tr>
            <th>Aangepast</th>
            <td>{dayjs(group?.updated_at).fromNow()} op {dayjs(group?.updated_at).format('DD-MM-YYYY HH:mm:ss')}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

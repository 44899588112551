import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchGrades } from '../features/grade/gradeAPI';
import { selectGrades } from '../features/grade/gradeSlice';

export const useGrades = () => {
  const dispatch = useAppDispatch();
  const grades = useAppSelector(selectGrades);

  useEffect(() => {
    if (grades !== null) {
      return;
    }

    dispatch(fetchGrades());
  }, [grades, dispatch]);

  return grades;
};

import React from 'react';
import { MainFooter } from './MainFooter';
import { MainMenu } from './main-menu/MainMenu';

type Props = {
  children: JSX.Element;
}

export const Layout = ({ children }: Props) => (
  <div className="h-full flex flex-col">
    <MainMenu />
    <div className="flex-1 flex flex-col main-content overflow-y-hidden">
      {children}
    </div>
    <MainFooter />
  </div>
);

import React from 'react';
import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Grade } from '../../types/grade';
import { Button } from '../Button';
import { ButtonGroup } from '../button-group/ButtonGroup';
import { FormGroup } from '../form/FormGroup';
import { FormInput } from '../form/FormInput';

type Props = {
  grade?: Grade;
  onSave: (grade: Grade) => void | Promise<void>;
  onCancel: () => void;
};

export const GradeForm = ({ grade, onSave, onCancel }: Props) => {
  const { register, handleSubmit } = useForm<Grade>({ defaultValues: grade });

  const onSubmit: SubmitHandler<Grade> = useCallback((data) => {
    onSave(data);
  }, [onSave]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label="Naam" required>
        <FormInput type="text" {...register('name', { required: true })} />
      </FormGroup>

      <div className="mt-4">
        <ButtonGroup>
          <Button onClick={onCancel} className="mr-2">Annuleren</Button>
          <Button type="submit" primary>Opslaan</Button>
        </ButtonGroup>
      </div>
    </form>
  );
};

import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Column, Table } from '../../../components/table/Table';
import { Header } from '../../../components/typography/Header';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { useRegions } from '../../../hooks/useRegions';
import { Region } from '../../../types/region';

const getDate = (region: Region, key: keyof Region) => dayjs(region[key]).format('DD-MM-YYYY HH:mm:ss');

const columns: Column<Region>[] = [
  { label: 'Naam', getValue: (region: Region) => region.name || '-'},
  { label: 'Aangemaakt op', getValue: (region) => getDate(region, 'created_at') },
  { label: 'Aangepast op', getValue: (region) => getDate(region, 'updated_at') },
];

export const RegionSettings = () => {
  const regions = useRegions();
  const navigate = useNavigate();

  useKeyboardShortcuts({
    onCreateItem: () => navigate('create'),
  });

  return (
    <div>
      <Header>Regio&apos;s ({regions?.length || 0})</Header>
      <Button onClick={() => navigate('create')} className="mb-4">Regio toevoegen</Button>

      <Table
        columns={columns}
        data={regions}
        striped
        hover
        onRowClick={(region) => navigate(`/settings/regions/${region.id}`)}
      />
    </div>
  );
};

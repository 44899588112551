import React from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { FormatForm } from '../../../components/formats/FormatForm';
import { Header } from '../../../components/typography/Header';
import { storeFormat } from '../../../features/format/formatAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Format } from '../../../types/format';

export const FormatCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (format: Format) => {
    dispatch(storeFormat(format));
    navigate('/settings/formats');
  }, [dispatch, navigate]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Formaat aanmaken</Header>
      <FormatForm onSave={onSave} onCancel={onCancel} />
    </div>
  );
};

import axios from 'axios';
import AuthenticationService from './AuthenticationService';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    'Accept': 'application/json',
  },
});

apiClient.interceptors.request.use(async function (config) {
  const accessToken = await AuthenticationService.getAccessToken();
  config.headers.set('Authorization', `Bearer ${accessToken}`);
  return config;
});

export default apiClient;

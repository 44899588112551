import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Format } from '../../types/format';

export interface FormatState {
  formats: Format[] | null;
}

const initialState: FormatState = {
  formats: null,
};

export const formatSlice = createSlice({
  name: 'format',
  initialState,
  reducers: {
    formatsLoaded: (state, action: PayloadAction<Format[]>) => {
      state.formats = action.payload;
    },
    addFormat: (state, action: PayloadAction<Format>) => {
      if (state.formats === null) {
        state.formats = [];
      }
      state.formats.push(action.payload);
    },
    updateFormat: (state, action: PayloadAction<Format>) => {
      const index = state.formats?.findIndex((r) => r.id === action.payload.id);
      if (index === -1 || index === undefined) {
        return;
      }

      state.formats?.splice(index, 1, action.payload);
    },
    removeFormat: (state, action: PayloadAction<string>) => {
      const index = state.formats?.findIndex((r) => r.id === action.payload);
      if (index === -1 || index === undefined) {
        return;
      }

      state.formats?.splice(index, 1);
    },
  },
});

export const selectFormats = (state: RootState) => state.format.formats;
export const selectFormat = (state: RootState, formatId: string) => state.format.formats?.find((r) => r.id === formatId) || null;

export const { formatsLoaded, addFormat, updateFormat, removeFormat } = formatSlice.actions;

export default formatSlice.reducer;

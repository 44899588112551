import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Column, Table } from '../../../components/table/Table';
import { Header } from '../../../components/typography/Header';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { useMaterials } from '../../../hooks/useMaterials';
import { Material } from '../../../types/material';

const getDate = (material: Material, key: keyof Material) => dayjs(material[key]).format('DD-MM-YYYY HH:mm:ss');

const columns: Column<Material>[] = [
  { label: 'Naam', getValue: (material: Material) => material.name || '-'},
  { label: 'Aangemaakt op', getValue: (material) => getDate(material, 'created_at') },
  { label: 'Aangepast op', getValue: (material) => getDate(material, 'updated_at') },
];

export const MaterialSettings = () => {
  const materials = useMaterials();
  const navigate = useNavigate();

  useKeyboardShortcuts({
    onCreateItem: () => navigate('create'),
  });

  return (
    <div>
      <Header>Materialen ({materials?.length || 0})</Header>
      <Button onClick={() => navigate('create')} className="mb-4">Materiaal toevoegen</Button>

      <Table
        columns={columns}
        data={materials}
        striped
        hover
        onRowClick={(material) => navigate(`/settings/materials/${material.id}`)}
      />
    </div>
  );
};

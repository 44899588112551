import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { Alert } from '../../../components/Alert';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/button-group/ButtonGroup';
import { Header } from '../../../components/typography/Header';
import { deleteCountry } from '../../../features/country/countryAPI';
import { useCountry } from '../../../hooks/useCountry';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';

export const CountryDelete = () => {
  const { countryId } = useParams();
  const { country } = useCountry(countryId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    if (!countryId) {
      return;
    }

    dispatch(deleteCountry(countryId));
    navigate('/settings/countries');
  }, [countryId, dispatch, navigate]);

  useKeyboardShortcuts({ onCancel: () => navigate(-1) });

  return (
    <div>
      <Header>Land verwijderen</Header>
      <Alert type="error">
        <p>Weet je zeker dat je &apos;{country?.name}&apos; wilt verwijderen?</p>

        <div className="mt-3">
          <ButtonGroup>
            <Button onClick={() => navigate(-1)}>Annuleren</Button>
            <Button onClick={handleDelete}>Verwijderen</Button>
          </ButtonGroup>
        </div>
      </Alert>
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Order } from '../../types/order';
import { Tag } from '../../types/tag';

export interface CoinState {
  tags: Tag[] | null;
  page: number;
  order: Order;
  orderBy: keyof Tag;
}

const initialState: CoinState = {
  tags: null,
  page: 1,
  order: 'desc',
  orderBy: 'created_at',
};

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    tagsLoaded: (state, action: PayloadAction<Tag[]>) => {
      state.tags = action.payload;
    },
    persistTag: (state, action: PayloadAction<Tag>) => {
      if (state.tags === null) {
        state.tags = [];
      }

      const index = state.tags.findIndex((t) => t.id === action.payload.id);
      if (index === -1 || index === undefined) {
        state.tags.push(action.payload);
        return;
      }

      state.tags.splice(index, 1, action.payload);
    },
    setTagPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setTagOrder: (state, action: PayloadAction<Order>) => {
      state.order = action.payload;
    },
    setTagOrderBy: (state, action: PayloadAction<keyof Tag>) => {
      state.orderBy = action.payload;
    },
  },
});

export const selectTags = (state: RootState) => state.tag.tags;
export const selectTag = (state: RootState, tagId: string) => state.tag.tags?.find((t) => t.id === tagId) || null;
export const selectTagPage = (state: RootState) => state.tag.page;
export const selectTagOrder = (state: RootState) => state.tag.order;
export const selectTagOrderBy = (state: RootState) => state.tag.orderBy;

export const {
  tagsLoaded,
  persistTag,
  setTagPage,
  setTagOrder,
  setTagOrderBy,
} = tagSlice.actions;

export default tagSlice.reducer;

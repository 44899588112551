import React from 'react';
import { ChangeEventHandler, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { fileToBase64 } from '../../services/FileService';
import { Group } from '../../types/group';
import { Button } from '../Button';
import { ButtonGroup } from '../button-group/ButtonGroup';
import { FormGroup } from '../form/FormGroup';
import { FormInput } from '../form/FormInput';

type Props = {
  group?: Group;
  onSave: (group: Group) => void | Promise<void>;
  onCancel: () => void;
};

export const GroupForm = ({ group, onSave, onCancel }: Props) => {
  const { register, handleSubmit, setValue } = useForm<Group>({ defaultValues: group });

  const onSubmit: SubmitHandler<Group> = useCallback((data) => {
    onSave(data);
  }, [onSave]);

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = useCallback(async (e) => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      const base64File = await fileToBase64(e.target.files[0]);
      setValue('coverImage', base64File.split('base64,')[1]);
    }
  }, [setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label="Naam" required>
        <FormInput type="text" {...register('name', { required: true })} />
      </FormGroup>

      <FormGroup label="Cover afbeelding">
        <input type="file" onChange={handleFileChange} />
      </FormGroup>

      <div className="mt-4">
        <ButtonGroup>
          <Button onClick={onCancel} className="mr-2">Annuleren</Button>
          <Button type="submit" primary>Opslaan</Button>
        </ButtonGroup>
      </div>
    </form>
  );
};

import React from 'react';
import { ReactNode, useCallback } from 'react';
import { MessageType } from '../types/message';

type Props = {
  children: ReactNode;
  type: MessageType;
  className?: string;
};

export const Alert = ({ children, type, className }: Props) => {
  const getBackgroundColor = useCallback(() => {
    switch (type) {
    case 'success':
      return 'bg-green-800';
    case 'error':
      return 'bg-red-800';
    case 'info':
      return 'bg-blue-800';
    }
  }, [type]);

  const getTextColor = useCallback(() => {
    switch (type) {
    case 'success':
      return 'text-green-300';
    case 'error':
      return 'text-red-300';
    case 'info':
      return 'text-blue-300';
    }
  }, [type]);

  return (
    <div
      className={`rounded-lg px-5 py-3 ${getBackgroundColor()} ${getTextColor()} ${className}`}
    >
      {children}
    </div>
  );
};

import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Column, Table } from '../../../components/table/Table';
import { Header } from '../../../components/typography/Header';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { useSellers } from '../../../hooks/useSellers';
import { Seller } from '../../../types/seller';

const getDate = (seller: Seller, key: keyof Seller) => dayjs(seller[key]).format('DD-MM-YYYY HH:mm:ss');

const columns: Column<Seller>[] = [
  { label: 'Naam', getValue: (seller: Seller) => seller.name || '-'},
  { label: 'Website', getValue: (seller: Seller) => seller.website || '-'},
  { label: 'Aangemaakt op', getValue: (seller) => getDate(seller, 'created_at') },
  { label: 'Aangepast op', getValue: (seller) => getDate(seller, 'updated_at') },
];

export const SellerSettings = () => {
  const sellers = useSellers();
  const navigate = useNavigate();

  useKeyboardShortcuts({
    onCreateItem: () => navigate('create'),
  });

  return (
    <div>
      <Header>Verkopers ({sellers?.length || 0})</Header>
      <Button onClick={() => navigate('create')} className="mb-4">Verkoper toevoegen</Button>

      <Table
        columns={columns}
        data={sellers}
        striped
        hover
        onRowClick={(seller) => navigate(`/settings/sellers/${seller.id}`)}
      />
    </div>
  );
};

import React from 'react';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/button-group/ButtonGroup';
import { Header } from '../../../components/typography/Header';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { useSeller } from '../../../hooks/useSeller';

export const SellerDetails = () => {
  const { sellerId } = useParams();
  const { seller } = useSeller(sellerId);
  const navigate = useNavigate();

  useKeyboardShortcuts({
    onCancel: () => navigate(-1),
    onEditItem: () => navigate(`/settings/sellers/${sellerId}/edit`),
    onDeleteItem: () => navigate(`/settings/sellers/${sellerId}/delete`),
  });

  return (
    <div>
      <Header>{seller?.name || 'Verkoper'}</Header>

      <ButtonGroup>
        <Button onClick={() => navigate(-1)}>Terug</Button>
        <Button onClick={() => navigate(`/settings/sellers/${sellerId}/edit`)}>Aanpassen</Button>
        <Button onClick={() => navigate(`/settings/sellers/${sellerId}/delete`)}>Verwijderen</Button>
      </ButtonGroup>

      <table className="mt-4">
        <tbody>
          <tr>
            <th>Website</th>
            <td>{seller?.website}</td>
          </tr>
          <tr>
            <th>Notities</th>
            <td>{seller?.notes}</td>
          </tr>
          <tr>
            <th>Aangemaakt</th>
            <td>{dayjs(seller?.created_at).fromNow()} op {dayjs(seller?.created_at).format('DD-MM-YYYY HH:mm:ss')}</td>
          </tr>
          <tr>
            <th>Aangepast</th>
            <td>{dayjs(seller?.updated_at).fromNow()} op {dayjs(seller?.updated_at).format('DD-MM-YYYY HH:mm:ss')}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

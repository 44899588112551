import React from 'react';
import { selectCoinFilter, selectNominalValues, selectYears, setCoinFilter } from '../../features/coin/coinSlice';
import { CoinFilter as CoinFilterType } from '../../types/coin-filter';
import { Button } from '../Button';
import { FormInput } from '../form/FormInput';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CountryFilter } from './coin-filter/CountryFilter';
import { FormatFilter } from './coin-filter/FormatFilter';
import { GradeFilter } from './coin-filter/GradeFilter';
import { GroupFilter } from './coin-filter/GroupFilter';
import { MaterialFilter } from './coin-filter/MaterialFilter';
import { NominalValueFilter } from './coin-filter/NominalValueFilter';
import { PicturesFilter } from './coin-filter/PicturesFilter';
import { RegionFilter } from './coin-filter/RegionFilter';
import { SellerFilter } from './coin-filter/SellerFilter';
import { StatusFilter } from './coin-filter/StatusFilter';
import { TagsFilter } from './coin-filter/TagsFilter';
import { UnitFilter } from './coin-filter/UnitFilter';
import { YearFilter } from './coin-filter/YearFilter';

export const defaultFilter: CoinFilterType = {
  country: null,
  unit: null,
  status: null,
  seller: null,
  region: null,
  format: null,
  grade: null,
  material: null,
  term: null,
  year: null,
  group: null,
  withoutPictures: false,
  nominalValue: null,
  withoutTag: false,
};

export const CoinFilter = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectCoinFilter);
  const onFilterChange = useCallback((changedFilter: CoinFilterType) => dispatch(setCoinFilter(changedFilter)), [dispatch]);
  const nominalValues = useAppSelector(selectNominalValues);
  const years = useAppSelector(selectYears);

  const handleResetFilter = useCallback(() => {
    dispatch(setCoinFilter(defaultFilter));
  }, [dispatch]);

  return (
    <div className="flex flex-col gap-4">
      <div>
        <FormInput
          type="text"
          value={filter.term || ''}
          onChange={(e) => onFilterChange({ ...filter, term: e.target.value })}
          onKeyDown={(e) => e.stopPropagation()}
          placeholder="Zoeken op naam..."
        />
      </div>

      <YearFilter filter={filter} onChange={onFilterChange} years={years} />
      <CountryFilter filter={filter} onChange={onFilterChange} />
      <FormatFilter filter={filter} onChange={onFilterChange} />
      <GradeFilter filter={filter} onChange={onFilterChange} />
      <MaterialFilter filter={filter} onChange={onFilterChange} />
      <RegionFilter filter={filter} onChange={onFilterChange} />
      <SellerFilter filter={filter} onChange={onFilterChange} />
      <StatusFilter filter={filter} onChange={onFilterChange} />
      <UnitFilter filter={filter} onChange={onFilterChange} />
      <GroupFilter filter={filter} onChange={onFilterChange} />
      <NominalValueFilter filter={filter} onChange={onFilterChange} nominalValues={nominalValues} />
      <PicturesFilter filter={filter} onChange={onFilterChange} />
      <TagsFilter filter={filter} onChange={onFilterChange} />

      <Button onClick={handleResetFilter}>Reset filter</Button>
    </div>
  );
};

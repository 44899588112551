import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchGroup } from '../features/group/groupAPI';

export const useGroup = (groupId: string | undefined) => {
  const dispatch = useAppDispatch();
  const group = useAppSelector((state) => state.group.groups?.find((c) => c.id === groupId));

  useEffect(() => {
    if (!groupId) {
      return;
    }

    dispatch(fetchGroup(groupId));
  }, [groupId, dispatch]);

  return { group };
};

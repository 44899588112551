import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { GradeForm } from '../../../components/grades/GradeForm';
import { Header } from '../../../components/typography/Header';
import { storeGrade } from '../../../features/grade/gradeAPI';
import { useGrade } from '../../../hooks/useGrade';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Grade } from '../../../types/grade';

export const GradeEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { gradeId } = useParams();
  const { grade } = useGrade(gradeId);

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (grade: Grade) => {
    dispatch(storeGrade(grade));
    navigate(`/settings/grades/${gradeId}`);
  }, [dispatch, navigate, gradeId]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Formaat aanpassen</Header>
      {grade && <GradeForm onSave={onSave} onCancel={onCancel} grade={grade} />}
    </div>
  );
};

import React from 'react';
import { ChangeEventHandler, useCallback } from 'react';
import { FilterProps } from './filter.types';

export function PicturesFilter<T extends {withoutPictures: boolean}>({ filter, onChange }: FilterProps<T>) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    onChange({ ...filter, withoutPictures: event.target.checked });
  }, [filter, onChange]);

  return (
    <label htmlFor="filter-without-pictures">
      <input type="checkbox" id="filter-without-pictures" checked={filter.withoutPictures} onChange={handleChange} />
      <span className="ml-2">Alleen munten zonder afbeelding laten zien</span>
    </label>
  );
}

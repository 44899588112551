import React from 'react';
import { RouteObject } from 'react-router-dom';
import { GroupCreate } from '../../views/settings/groups/Create';
import { GroupDelete } from '../../views/settings/groups/Delete';
import { GroupDetails } from '../../views/settings/groups/Details';
import { GroupEdit } from '../../views/settings/groups/Edit';
import { GroupSettings } from '../../views/settings/groups/Groups';

export const GroupRoutes: RouteObject[] = [
  {
    path: 'groups',
    element: <GroupSettings />,
  },
  {
    path: 'groups/create',
    element: <GroupCreate />,
  },
  {
    path: 'groups/:groupId',
    element: <GroupDetails />
  },
  {
    path: 'groups/:groupId/edit',
    element: <GroupEdit />
  },
  {
    path: 'groups/:groupId/delete',
    element: <GroupDelete />
  },
];

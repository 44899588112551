import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchStatus } from '../features/status/statusAPI';

export const useStatus = (statusId: string | undefined) => {
  const dispatch = useAppDispatch();
  const status = useAppSelector((state) => state.status.statuses?.find((r) => r.id === statusId));

  useEffect(() => {
    if (!statusId) {
      return;
    }

    dispatch(fetchStatus(statusId));
  }, [statusId, dispatch]);

  return { status };
};

import React from 'react';
import { RouteObject } from 'react-router-dom';
import { MaterialCreate } from '../../views/settings/materials/Create';
import { MaterialDelete } from '../../views/settings/materials/Delete';
import { MaterialDetails } from '../../views/settings/materials/Details';
import { MaterialEdit } from '../../views/settings/materials/Edit';
import { MaterialSettings } from '../../views/settings/materials/Materials';

export const MaterialRoutes: RouteObject[] = [
  {
    path: 'materials',
    element: <MaterialSettings />,
  },
  {
    path: 'materials/create',
    element: <MaterialCreate />,
  },
  {
    path: 'materials/:materialId',
    element: <MaterialDetails />,
  },
  {
    path: 'materials/:materialId/edit',
    element: <MaterialEdit />,
  },
  {
    path: 'materials/:materialId/delete',
    element: <MaterialDelete />,
  },
];

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { defaultFilter } from '../../components/stamps/StampFilter';
import { Order } from '../../types/order';
import { Picture } from '../../types/picture';
import { Stamp } from '../../types/stamp';
import { StampFilter } from '../../types/stamp-filter';

export interface StampState {
  stamps: Stamp[] | null;
  selectedStampId: string | null;
  orderBy: keyof Stamp;
  order: Order;
  shownStampBadge: keyof Stamp;
  stampFilter: StampFilter;
  showFilter: boolean;
}

const initialState: StampState = {
  stamps: null,
  selectedStampId: null,
  orderBy: 'name',
  order: 'asc',
  shownStampBadge: 'year',
  stampFilter: defaultFilter,
  showFilter: false,
};

export const stampSlice = createSlice({
  name: 'stamp',
  initialState,
  reducers: {
    stampsLoaded: (state, action: PayloadAction<Stamp[]>) => {
      state.stamps = action.payload;
    },
    persistStamp: (state, action: PayloadAction<Stamp>) => {
      if (state.stamps === null) {
        state.stamps = [];
      }

      const index = state.stamps.findIndex((s) => s.id === action.payload.id);
      if (index === -1 || index === undefined) {
        state.stamps.push(action.payload);
        return;
      }

      state.stamps.splice(index, 1, action.payload);
    },
    removeStamp: (state, action: PayloadAction<string>) => {
      const index = state.stamps?.findIndex((s) => s.id === action.payload);
      if (index === -1 || index === undefined) {
        return;
      }

      state.stamps?.splice(index, 1);

      if (state.selectedStampId === action.payload) {
        state.selectedStampId = null;
      }
    },
    setStampOrderBy: (state, action: PayloadAction<keyof Stamp>) => {
      state.orderBy = action.payload;
    },
    setStampOrder: (state, action: PayloadAction<Order>) => {
      state.order = action.payload;
    },
    setShownStampBadge: (state, action: PayloadAction<keyof Stamp>) => {
      state.shownStampBadge = action.payload;
    },
    setSelectedStampId: (state, action: PayloadAction<string | null>) => {
      state.selectedStampId = action.payload;
    },
    setStampFilter: (state, action: PayloadAction<StampFilter>) => {
      state.stampFilter = action.payload;
    },
    setShowStampFilter: (state, action: PayloadAction<boolean>) => {
      state.showFilter = action.payload;
    },
    addStampPicture: (state, action: PayloadAction<{ picture: Picture, stampId: string }>) => {
      const stamp = state.stamps?.find((c) => c.id === action.payload.stampId);
      if (!stamp) {
        return;
      }

      if (stamp.pictures === undefined) {
        stamp.pictures = [];
      }

      stamp.pictures.push(action.payload.picture);
    },
  },
});

export const selectStamps = (state: RootState) => state.stamp.stamps;
export const selectStamp = (state: RootState, stampId: string) => state.stamp.stamps?.find((s) => s.id === stampId) || null;
export const selectSelectedStampId = (state: RootState) => state.stamp.selectedStampId;
export const selectStampOrderBy = (state: RootState) => state.stamp.orderBy;
export const selectStampOrder = (state: RootState) => state.stamp.order;
export const selectShownStampBadge = (state: RootState) => state.stamp.shownStampBadge;
export const selectStampYears = (state: RootState) => [...new Set(state.stamp.stamps?.map((s) => s.year)?.filter((y) => y !== undefined)?.sort() || [])];
export const selectStampNominalValues = (state: RootState) => [...new Set(state.stamp.stamps?.map((s) => s.nominalValue).filter((y) => y !== undefined)?.sort() || [])];
export const selectStampFilter = (state: RootState) => state.stamp.stampFilter;
export const selectShowStampFilter = (state: RootState) => state.stamp.showFilter;

export const {
  stampsLoaded,
  persistStamp,
  removeStamp,
  setStampOrderBy,
  setStampOrder,
  setShownStampBadge,
  setSelectedStampId,
  setStampFilter,
  setShowStampFilter,
  addStampPicture,
} = stampSlice.actions;

export default stampSlice.reducer;

import React from 'react';
import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Seller } from '../../types/seller';
import { Button } from '../Button';
import { ButtonGroup } from '../button-group/ButtonGroup';
import { FormGroup } from '../form/FormGroup';
import { FormInput } from '../form/FormInput';

type Props = {
  seller?: Seller;
  onSave: (seller: Seller) => void | Promise<void>;
  onCancel: () => void;
};

export const SellerForm = ({ seller, onSave, onCancel }: Props) => {
  const { register, handleSubmit } = useForm<Seller>({ defaultValues: seller });

  const onSubmit: SubmitHandler<Seller> = useCallback((data) => {
    onSave(data);
  }, [onSave]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label="Naam" required>
        <FormInput type="text" {...register('name', { required: true })} />
      </FormGroup>

      <FormGroup label="Website">
        <FormInput type="url" {...register('website')} />
      </FormGroup>

      <FormGroup label="Notities">
        <FormInput type="text" {...register('notes')} />
      </FormGroup>

      <div className="mt-4">
        <ButtonGroup>
          <Button onClick={onCancel} className="mr-2">Annuleren</Button>
          <Button type="submit" primary>Opslaan</Button>
        </ButtonGroup>
      </div>
    </form>
  );
};

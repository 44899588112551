import React from 'react';
import { useMemo } from 'react';
import { useRegions } from '../../../hooks/useRegions';
import { Region } from '../../../types/region';
import { convertEntityToOption } from './filter.helper';
import { FilterProps } from './filter.types';
import { FilterSelect } from './FilterSelect';

export function RegionFilter<T extends {region: Region|null}>({ filter, onChange }: FilterProps<T>) {
  const regions = useRegions();
  const regionOptions = useMemo(() => regions?.map(convertEntityToOption) || [], [regions]);
  const selectedRegionOption = useMemo(() => regionOptions.find((region) => region.value === filter.region?.id), [regionOptions, filter]);

  return (
    <FilterSelect
      onChange={(value) => onChange({ ...filter, region: regions?.find((c) => c.id === value?.value) || null })}
      selectedOption={selectedRegionOption}
      options={regionOptions}
      placeholder="Selecteer een regio..."
    />
  );
}

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchUnits } from '../features/unit/unitAPI';
import { selectUnits } from '../features/unit/unitSlice';

export const useUnits = () => {
  const dispatch = useAppDispatch();
  const units = useAppSelector(selectUnits);

  useEffect(() => {
    if (units !== null) {
      return;
    }

    dispatch(fetchUnits());
  }, [units, dispatch]);

  return units;
};

import React from 'react';
import { RouteObject } from 'react-router-dom';
import { SellerCreate } from '../../views/settings/sellers/Create';
import { SellerDelete } from '../../views/settings/sellers/Delete';
import { SellerDetails } from '../../views/settings/sellers/Details';
import { SellerEdit } from '../../views/settings/sellers/Edit';
import { SellerSettings } from '../../views/settings/sellers/Sellers';

export const SellerRoutes: RouteObject[] = [
  {
    path: 'sellers',
    element: <SellerSettings />,
  },
  {
    path: 'sellers/create',
    element: <SellerCreate />,
  },
  {
    path: 'sellers/:sellerId',
    element: <SellerDetails />,
  },
  {
    path: 'sellers/:sellerId/edit',
    element: <SellerEdit />,
  },
  {
    path: 'sellers/:sellerId/delete',
    element: <SellerDelete />,
  },
];

import { AppThunk } from '../../app/store';
import apiClient from '../../services/apiClient';
import { Status } from '../../types/status';
import { addErrorMessage, addSuccessMessage } from '../base/baseAPI';
import { appFinishedLoading, appIsLoading } from '../base/baseSlice';
import { addStatus, removeStatus, selectStatus, statusesLoaded, updateStatus } from './statusSlice';

const apiEndpoint = '/statuses';

export const fetchStatuses = (): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<Status[]>(apiEndpoint);
    dispatch(statusesLoaded(response.data));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de statussen.'));
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const fetchStatus = (statusId: string): AppThunk<Promise<Status>> => async (dispatch, getState) => {
  const status = selectStatus(getState(), statusId);
  if (status !== null) {
    return status;
  }

  dispatch(appIsLoading());
  try {
    const response = await apiClient.get<Status>(`${apiEndpoint}/${statusId}`);
    dispatch(addStatus(response.data));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het laden van de status ${statusId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const storeStatus = (status: Status): AppThunk<Promise<Status>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    let response;
    if (status.id === undefined) {
      // Store new status
      response = await apiClient.post<Status>(apiEndpoint, status);
      dispatch(addStatus(response.data));
    } else {
      // Update existing status
      response = await apiClient.put<Status>(`${apiEndpoint}/${status.id}`, status);
      dispatch(updateStatus(response.data));
    }

    dispatch(addSuccessMessage(`Status '${status.name}' is opgeslagen!`));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het opslaan van de status.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const deleteStatus = (statusId: string): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());
  try {
    await apiClient.delete(`${apiEndpoint}/${statusId}`);
    dispatch(removeStatus(statusId));
    dispatch(addSuccessMessage('De status is verwijderd.'));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het verwijderen van de status ${statusId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

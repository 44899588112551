import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import { OpenidConfiguration } from '../types/openid-configuration';
import { TokenResponse } from '../types/token-response';
import { User } from '../types/user';

class AuthenticationService {
  private static STATE_STORAGE_KEY = 'oidc-state';
  private static CODE_VERIFIER_STORAGE_KEY = 'oidc-code-verifier';
  private static TOKEN_STORAGE_KEY = 'oidc-token';

  private refreshRequest: Promise<TokenResponse> | null = null;

  async getConfiguration(): Promise<OpenidConfiguration> {
    const response = await fetch(process.env.REACT_APP_OIDC_DISCOVERY_URI);
    const data = await response.json();
    return {
      authorizationEndpoint: data['authorization_endpoint'],
      jwksUri: data['jwks_uri'],
      tokenEndpoint: data['token_endpoint'],
      userinfoEndpoint: data['userinfo_endpoint'],
    };
  }

  async getAuthenticationUrl(): Promise<string> {
    const configuration = await this.getConfiguration();

    const state = uuidv4();
    localStorage.setItem(AuthenticationService.STATE_STORAGE_KEY, state);

    const codeVerifier = this.generateRandomString(128);
    const codeChallenge = this.generateCodeChallenge(codeVerifier);
    localStorage.setItem(
      AuthenticationService.CODE_VERIFIER_STORAGE_KEY,
      codeVerifier
    );

    const url = new URL(configuration.authorizationEndpoint);
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('client_id', this.getClientId());
    url.searchParams.append('redirect_uri', this.getRedirectUri());
    url.searchParams.append('scope', 'openid email offline_access');
    url.searchParams.append('state', state);
    url.searchParams.append('code_challenge', codeChallenge);
    url.searchParams.append('code_challenge_method', 'S256');
    return url.toString();
  }

  async getTokensFromCode(code: string, state: string): Promise<TokenResponse> {
    const sessionState = localStorage.getItem(
      AuthenticationService.STATE_STORAGE_KEY
    );
    localStorage.removeItem(AuthenticationService.STATE_STORAGE_KEY);
    if (sessionState !== state) {
      throw new Error('State mismatch');
    }

    const codeVerifier = localStorage.getItem(
      AuthenticationService.CODE_VERIFIER_STORAGE_KEY
    );
    localStorage.removeItem(AuthenticationService.CODE_VERIFIER_STORAGE_KEY);
    if (!codeVerifier) {
      throw new Error('Missing code verifier in session storage');
    }

    const body = new URLSearchParams();
    body.append('grant_type', 'authorization_code');
    body.append('code', code);
    body.append('redirect_uri', this.getRedirectUri());
    body.append('client_id', this.getClientId());
    body.append('code_verifier', codeVerifier);
    return this.doTokenRequest(body);
  }

  async refreshTokens(refreshToken: string): Promise<TokenResponse> {
    if (this.refreshRequest !== null) {
      return await this.refreshRequest;
    }

    const body = new URLSearchParams();
    body.append('grant_type', 'refresh_token');
    body.append('refresh_token', refreshToken);
    body.append('client_id', this.getClientId());
    try {
      this.refreshRequest = this.doTokenRequest(body);
      const response = await this.refreshRequest;
      this.refreshRequest = null;
      return response;
    } catch (e) {
      if (e instanceof Response && e.status === 400) {
        // Cannot refresh tokens, logout and let the user authenticate again
        localStorage.removeItem(AuthenticationService.TOKEN_STORAGE_KEY);
        window.location.href = await this.getAuthenticationUrl();
      }

      throw e;
    }
  }

  async getAccessToken(): Promise<string> {
    const tokenResponseData = localStorage.getItem(
      AuthenticationService.TOKEN_STORAGE_KEY
    );
    if (!tokenResponseData) {
      window.location.href = await this.getAuthenticationUrl();
      throw new Error('No tokens in localstorage, re-authenticate');
    }

    let tokenResponse: TokenResponse = JSON.parse(tokenResponseData);
    if (!tokenResponse.accessToken || !tokenResponse.refreshToken) {
      window.location.href = await this.getAuthenticationUrl();
      throw new Error('No tokens in localstorage, re-authenticate');
    }

    if (this.isAccessTokenValid(tokenResponse.accessToken)) {
      return tokenResponse.accessToken;
    }

    // Try to refresh access token with refresh token
    tokenResponse = await this.refreshTokens(tokenResponse.refreshToken);
    if (this.isAccessTokenValid(tokenResponse.accessToken)) {
      return tokenResponse.accessToken;
    }

    window.location.href = await this.getAuthenticationUrl();
    throw new Error('No valid access/refresh token in localstorage found');
  }

  async getUser(): Promise<User> {
    const token = await this.getAccessToken();
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Cannot get user from invalid token');
    }

    const payloadData = atob(parts[1]);
    const payload = JSON.parse(payloadData);

    return {
      name: payload.name,
      given_name: payload.given_name,
      family_name: payload.family_name,
      email: payload.email,
    };
  }

  private getRedirectUri(): string {
    return process.env.REACT_APP_OIDC_REDIRECT_URI;
  }

  private getClientId(): string {
    return process.env.REACT_APP_OIDC_CLIENT_ID;
  }

  private async doTokenRequest(body: URLSearchParams): Promise<TokenResponse> {
    const configuration = await this.getConfiguration();
    const response = await fetch(configuration.tokenEndpoint, {
      method: 'POST',
      body,
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    const tokenResponse = {
      accessToken: data['access_token'],
      refreshToken: data['refresh_token'],
      idToken: data['id_token'],
    };
    localStorage.setItem(
      AuthenticationService.TOKEN_STORAGE_KEY,
      JSON.stringify(tokenResponse)
    );
    return tokenResponse;
  }

  private generateRandomString(length: number) {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  private generateCodeChallenge(codeVerifier: string): string {
    return this.base64URL(CryptoJS.SHA256(codeVerifier));
  }

  private base64URL(string: CryptoJS.lib.WordArray): string {
    return string
      .toString(CryptoJS.enc.Base64)
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  }

  private isAccessTokenValid(accessToken: string): boolean {
    const parts = accessToken.split('.');
    if (parts.length !== 3) {
      return false;
    }

    const payloadData = atob(parts[1]);
    const payload = JSON.parse(payloadData);

    if (!payload.exp) {
      return false;
    }

    return payload.exp > (Date.now() + 5000) / 1000;
  }
}

const instance = new AuthenticationService();
export default instance;

import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { Alert } from '../../../components/Alert';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/button-group/ButtonGroup';
import { Header } from '../../../components/typography/Header';
import { deleteFormat } from '../../../features/format/formatAPI';
import { useFormat } from '../../../hooks/useFormat';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';

export const FormatDelete = () => {
  const { formatId } = useParams();
  const { format } = useFormat(formatId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    if (!formatId) {
      return;
    }

    dispatch(deleteFormat(formatId));
    navigate('/settings/formats');
  }, [formatId, dispatch, navigate]);

  useKeyboardShortcuts({ onCancel: () => navigate(-1) });

  return (
    <div>
      <Header>Formaat verwijderen</Header>
      <Alert type="error">
        <p>Weet je zeker dat je &apos;{format?.name}&apos; wilt verwijderen?</p>

        <div className="mt-3">
          <ButtonGroup>
            <Button onClick={() => navigate(-1)}>Annuleren</Button>
            <Button onClick={handleDelete}>Verwijderen</Button>
          </ButtonGroup>
        </div>
      </Alert>
    </div>
  );
};

import React from 'react';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Groups = lazy(() => import('../views/groups/Groups'));
const Group = lazy(() => import('../views/groups/Group'));

export const GroupRoutes: RouteObject[] = [
  {
    path: 'groups',
    element: <Groups />,
  },
  {
    path: 'groups/:groupId',
    element: <Group />,
  },
];

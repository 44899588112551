import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '../components/Alert';
import { selectMessages } from '../features/base/baseSlice';

type Props = {
  children: JSX.Element;
}

export const MessagesProvider = ({ children }: Props) => {
  const messages = useSelector(selectMessages);

  return (
    <>
      {children}
      <div className="fixed left-0 bottom-0 z-10">
        {messages.map((message) => (
          <Alert key={message.id} type={message.type} className="mb-3 ml-3">{message.message}</Alert>
        ))}
      </div>
    </>
  );
};

import React from 'react';
import { RouteObject } from 'react-router-dom';
import { GradeCreate } from '../../views/settings/grades/Create';
import { GradeDelete } from '../../views/settings/grades/Delete';
import { GradeDetails } from '../../views/settings/grades/Details';
import { GradeEdit } from '../../views/settings/grades/Edit';
import { GradeSettings } from '../../views/settings/grades/Grades';

export const GradeRoutes: RouteObject[] = [
  {
    path: 'grades',
    element: <GradeSettings />,
  },
  {
    path: 'grades/create',
    element: <GradeCreate />,
  },
  {
    path: 'grades/:gradeId',
    element: <GradeDetails />
  },
  {
    path: 'grades/:gradeId/edit',
    element: <GradeEdit />
  },
  {
    path: 'grades/:gradeId/delete',
    element: <GradeDelete />
  },
];

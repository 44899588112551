import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import regionReducer from '../features/region/regionSlice';
import baseReducer from '../features/base/baseSlice';
import countryReducer from '../features/country/countrySlice';
import formatReducer from '../features/format/formatSlice';
import gradeReducer from '../features/grade/gradeSlice';
import materialReducer from '../features/material/materialSlice';
import sellerReducer from '../features/seller/sellerSlice';
import statusReducer from '../features/status/statusSlice';
import unitReducer from '../features/unit/unitSlice';
import coinReducer from '../features/coin/coinSlice';
import groupReducer from '../features/group/groupSlice';
import pictureReducer from '../features/picture/pictureSlice';
import tagReducer from '../features/tag/tagSlice';
import stampReducer from '../features/stamp/stampSlice';

export const store = configureStore({
  reducer: {
    region: regionReducer,
    base: baseReducer,
    country: countryReducer,
    format: formatReducer,
    grade: gradeReducer,
    material: materialReducer,
    seller: sellerReducer,
    status: statusReducer,
    unit: unitReducer,
    coin: coinReducer,
    group: groupReducer,
    picture: pictureReducer,
    tag: tagReducer,
    stamp: stampReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

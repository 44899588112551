import React from 'react';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Stamps = lazy(() => import('../views/stamps/Stamps'));
const StampCreate = lazy(() => import('../views/stamps/Create'));
const StampEdit = lazy(() => import('../views/stamps/Edit'));
const StampDetails = lazy(() => import('../views/stamps/StampDetails'));
const StampDelete = lazy(() => import('../views/stamps/Delete'));

export const StampRoutes: RouteObject[] = [
  {
    path: 'stamps',
    element: <Stamps />,
  },
  {
    path: 'stamps/create',
    element: <StampCreate />,
  },
  {
    path: 'stamps/:stampId/edit',
    element: <StampEdit />,
  },
  {
    path: 'stamps/:stampId/delete',
    element: <StampDelete />,
  },
  {
    path: 'stamps/:stampId',
    element: <StampDetails />,
  },
];

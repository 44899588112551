import React from 'react';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectIsAppLoading, selectUser } from '../../features/base/baseSlice';
import { selectShowCoinFilter, setShowCoinFilter } from '../../features/coin/coinSlice';
import { selectShowStampFilter, setShowStampFilter } from '../../features/stamp/stampSlice';
import { MenuItem } from '../../types/menu-item';
import { MainMenuButton } from './MainMenuButton';

export const MainMenu = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isLoading = useSelector(selectIsAppLoading);
  const user = useSelector(selectUser);
  const showCoinFilter = useAppSelector(selectShowCoinFilter);
  const showStampFilter = useAppSelector(selectShowStampFilter);

  const menu: MenuItem[] = useMemo(() => [
    {
      label: 'Munten',
      link: '/',
      active: (pathname) => pathname === '/' || pathname.startsWith('/coins'),
      items: [
        {
          label: 'Aanmaken',
          link: '/coins/create',
        },
        {
          label: 'Scannen',
          link: '/tags/scan',
        },
        {
          label: 'Filter',
          link: '/',
          onClick: () => dispatch(setShowCoinFilter(!showCoinFilter)),
        },
        {
          label: 'Exporteren',
          link: '/coins/export',
        },
      ],
    },
    {
      label: 'Postzegels',
      link: '/stamps',
      items: [
        {
          label: 'Aanmaken',
          link: '/stamps/create',
        },
        {
          label: 'Filter',
          link: '/',
          onClick: () => dispatch(setShowStampFilter(!showStampFilter)),
        },
      ],
    },
    {
      label: 'Tags',
      link: '/tags',
      items: [
        {
          label: 'Genereren',
          link: '/tags/generate',
        },
        {
          label: 'Scannen',
          link: '/tags/scan',
        },
      ],
    },
    {
      label: 'Groepen',
      link: '/groups',
      items: [
        {
          label: 'Aanmaken',
          link: '/settings/groups/create',
        },
      ],
    },
    {
      label: 'Afbeeldingen',
      link: '/pictures',
    },
    {
      label: 'Overzicht',
      link: '/summary',
    },
    {
      label: 'Instellingen',
      link: '/settings',
      items: [
        {
          label: 'Regio\'s',
          link: '/settings/regions',
        },
        {
          label: 'Landen',
          link: '/settings/countries',
        },
        {
          label: 'Formaten',
          link: '/settings/formats',
        },
        {
          label: 'Kwaliteiten',
          link: '/settings/grades',
        },
        {
          label: 'Materialen',
          link: '/settings/materials',
        },
        {
          label: 'Verkopers',
          link: '/settings/sellers',
        },
        {
          label: 'Statussen',
          link: '/settings/statuses',
        },
        {
          label: 'Eenheden',
          link: '/settings/units',
        },
        {
          label: 'Groepen',
          link: '/settings/groups',
        },
      ],
    },
  ], [dispatch, showCoinFilter, showStampFilter]);

  const isActive = useCallback((item: MenuItem) => (
    item.active !== undefined ? item.active(location.pathname) : location.pathname.startsWith(item.link)
  ), [location]);

  const subItems = useMemo(() => menu.find((i) => isActive(i))?.items || [], [isActive, menu]);

  return (
    <>
      <div className="bg-gray-700 flex flex-row border-b-2 border-gray-800 items-center main-menu overflow-x-auto">
        {menu.map((item) => (
          <MainMenuButton
            key={item.link}
            to={item.link}
            onClick={item.onClick}
            active={isActive(item)}
          >
            {item.label}
          </MainMenuButton>
        ))}

        <div className="flex-1" />

        {isLoading && <div className="text-gray-400 mx-3">Laden...</div>}

        <div className="flex-1" />

        {user?.name && <div className="mx-3 whitespace-nowrap text-gray-400">{user.name}</div>}
      </div>

      {subItems.length > 0 && (
        <div className="bg-gray-800 flex flex-row border-b-2 border-gray-800 items-center main-menu overflow-x-auto">
          {subItems.map((item) => (
            <MainMenuButton key={item.link} to={item.link} onClick={item.onClick} active={isActive(item)}>{item.label}</MainMenuButton>
          ))}
        </div>
      )}
    </>
  );
};

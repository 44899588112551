import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Coin } from '../../types/coin';
import { CoinValueHistory } from '../../types/coin-value-history';
import { Order } from '../../types/order';
import { Picture } from '../../types/picture';
import { CoinFilter } from '../../types/coin-filter';
import { defaultFilter } from '../../components/coins/CoinFilter';

export interface CoinState {
  coins: Coin[] | null;
  selectedCoinId: string | null;
  orderBy: keyof Coin;
  order: Order;
  shownCoinBadge: keyof Coin;
  coinFilter: CoinFilter;
  showFilter: boolean;
}

const initialState: CoinState = {
  coins: null,
  selectedCoinId: null,
  orderBy: 'name',
  order: 'asc',
  shownCoinBadge: 'year',
  coinFilter: defaultFilter,
  showFilter: false,
};

export const coinSlice = createSlice({
  name: 'coin',
  initialState,
  reducers: {
    coinsLoaded: (state, action: PayloadAction<Coin[]>) => {
      state.coins = action.payload;
    },
    persistCoin: (state, action: PayloadAction<Coin>) => {
      if (state.coins === null) {
        state.coins = [];
      }

      const index = state.coins.findIndex((c) => c.id === action.payload.id);
      if (index === -1 || index === undefined) {
        state.coins.push(action.payload);
        return;
      }

      state.coins.splice(index, 1, action.payload);
    },
    removeCoin: (state, action: PayloadAction<string>) => {
      const index = state.coins?.findIndex((c) => c.id === action.payload);
      if (index === -1 || index === undefined) {
        return;
      }

      state.coins?.splice(index, 1);

      if (state.selectedCoinId === action.payload) {
        state.selectedCoinId = null;
      }
    },
    setCoinOrderBy: (state, action: PayloadAction<keyof Coin>) => {
      state.orderBy = action.payload;
    },
    setCoinOrder: (state, action: PayloadAction<Order>) => {
      state.order = action.payload;
    },
    setShownCoinBadge: (state, action: PayloadAction<keyof Coin>) => {
      state.shownCoinBadge = action.payload;
    },
    setSelectedCoinId: (state, action: PayloadAction<string | null>) => {
      state.selectedCoinId = action.payload;
    },
    setCoinValue: (state, action: PayloadAction<{ value: CoinValueHistory, coinId: string }>) => {
      const coin = state.coins?.find((c) => c.id === action.payload.coinId);
      if (!coin) {
        return;
      }

      if (coin.value_histories === undefined) {
        coin.value_histories = [];
      }

      coin.value_histories.unshift(action.payload.value);
    },
    addCoinPicture: (state, action: PayloadAction<{ picture: Picture, coinId: string }>) => {
      const coin = state.coins?.find((c) => c.id === action.payload.coinId);
      if (!coin) {
        return;
      }

      if (coin.pictures === undefined) {
        coin.pictures = [];
      }

      coin.pictures.push(action.payload.picture);
    },
    setCoinFilter: (state, action: PayloadAction<CoinFilter>) => {
      state.coinFilter = action.payload;
    },
    setShowCoinFilter: (state, action: PayloadAction<boolean>) => {
      state.showFilter = action.payload;
    },
  },
});

export const selectCoins = (state: RootState) => state.coin.coins;
export const selectCoin = (state: RootState, coinId: string) => state.coin.coins?.find((c) => c.id === coinId) || null;
export const selectSelectedCoinId = (state: RootState) => state.coin.selectedCoinId;
export const selectCoinOrderBy = (state: RootState) => state.coin.orderBy;
export const selectCoinOrder = (state: RootState) => state.coin.order;
export const selectShownCoinBadge = (state: RootState) => state.coin.shownCoinBadge;
export const selectYears = (state: RootState) => [...new Set(state.coin.coins?.map((c) => c.year)?.filter((y) => y !== undefined)?.sort() || [])];
export const selectNominalValues = (state: RootState) => [...new Set(state.coin.coins?.map((c) => c.nominalValue).filter((y) => y !== undefined)?.sort() || [])];
export const selectCoinFilter = (state: RootState) => state.coin.coinFilter;
export const selectShowCoinFilter = (state: RootState) => state.coin.showFilter;

export const {
  coinsLoaded,
  persistCoin,
  removeCoin,
  setCoinOrderBy,
  setCoinOrder,
  setSelectedCoinId,
  setCoinValue,
  addCoinPicture,
  setCoinFilter,
  setShowCoinFilter,
  setShownCoinBadge,
} = coinSlice.actions;

export default coinSlice.reducer;

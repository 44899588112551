import dayjs from 'dayjs';
import { AppThunk } from '../../app/store';
import apiClient from '../../services/apiClient';
import { Coin } from '../../types/coin';
import { CoinValueHistory } from '../../types/coin-value-history';
import { PaginatedResult } from '../../types/paginated-result';
import { addErrorMessage, addSuccessMessage } from '../base/baseAPI';
import { appFinishedLoading, appIsLoading } from '../base/baseSlice';
import { coinsLoaded, persistCoin, removeCoin, selectCoin, setCoinValue } from './coinSlice';

export const fetchCoins = (): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const headResponse = await apiClient.head('/coins');
    const pages = headResponse.headers['x-pages'];
    if (!pages) {
      throw new Error('Cannot get total pages from HEAD /coins');
    }

    const promises = [];
    for (let i = 1; i <= pages; i++) {
      promises.push(apiClient.get<PaginatedResult<Coin>>(`/coins?page=${i}`));
    }

    const responses = await Promise.all(promises);
    const coins = responses.flatMap((response) => response.data.data);
    dispatch(coinsLoaded(coins));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de munten.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const fetchCoin = (coinId: string, forceReload = false): AppThunk<Promise<Coin>> => async (dispatch, getState) => {
  const coin = selectCoin(getState(), coinId);
  if (coin !== null && !forceReload) {
    return coin;
  }

  dispatch(appIsLoading());
  try {
    const response = await apiClient.get<Coin>(`/coins/${coinId}`);
    dispatch(persistCoin(response.data));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het laden van munt ${coinId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const storeCoin = (coin: Coin): AppThunk<Promise<Coin>> => async (dispatch) => {
  dispatch(appIsLoading());

  const body = {
    ...coin,
    regionId: coin.region?.id,
    region: undefined,
    countryId: coin.country?.id,
    country: undefined,
    formatId: coin.format?.id,
    format: undefined,
    gradeId: coin.grade?.id,
    grade: undefined,
    materialId: coin.material?.id,
    material: undefined,
    sellerId: coin.seller?.id,
    seller: undefined,
    statusId: coin.status?.id,
    status: undefined,
    unitId: coin.unit?.id,
    unit: undefined,
    purchaseDate: coin.purchaseDate ? dayjs(coin.purchaseDate).format('YYYY-MM-DD') : undefined,
    pictures: undefined,
    groupId: coin.group?.id,
    group: undefined,
  };

  try {
    let response;
    if (coin.id === undefined) {
      // Store new coin
      response = await apiClient.post<Coin>('/coins', body);
    } else {
      response = await apiClient.put<Coin>(`/coins/${coin.id}`, body);
    }

    dispatch(persistCoin(response.data));
    dispatch(addSuccessMessage(`Munt '${coin.name}' is opgeslagen!`));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het opslaan van de munt.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const deleteCoin = (coinId: string): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    await apiClient.delete(`/coins/${coinId}`);
    dispatch(removeCoin(coinId));
    dispatch(addSuccessMessage('De munt is verwijderd.'));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het verwijderen van de munt.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const storeCoinValue = (coinId: string, value: number): AppThunk<Promise<void>> => async (dispatch, getState) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.post<CoinValueHistory>(`/coins/${coinId}/history`, {value});
    const coin = selectCoin(getState(), coinId);
    if (coin === null) {
      await dispatch(fetchCoin(coinId, true));
      return;
    }

    dispatch(setCoinValue({ value: response.data, coinId }));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het opslaan van de waarde.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const autoUpdateCoinData = (coinId: string): AppThunk<Promise<Coin>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<Coin>(`/coins/${coinId}/crawl`);
    dispatch(persistCoin(response.data));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgtreden tijdens het automatisch updaten van de munt.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

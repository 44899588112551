import { AppThunk } from '../../app/store';
import apiClient from '../../services/apiClient';
import { Unit } from '../../types/unit';
import { addErrorMessage, addSuccessMessage } from '../base/baseAPI';
import { appFinishedLoading, appIsLoading } from '../base/baseSlice';
import { addUnit, removeUnit, selectUnit, unitsLoaded, updateUnit } from './unitSlice';

const apiEndpoint = '/units';

export const fetchUnits = (): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<Unit[]>(apiEndpoint);
    dispatch(unitsLoaded(response.data));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de eenheden.'));
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const fetchUnit = (unitId: string): AppThunk<Promise<Unit>> => async (dispatch, getState) => {
  const unit = selectUnit(getState(), unitId);
  if (unit !== null) {
    return unit;
  }

  dispatch(appIsLoading());
  try {
    const response = await apiClient.get<Unit>(`${apiEndpoint}/${unitId}`);
    dispatch(addUnit(response.data));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het laden van de eenheid ${unitId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const storeUnit = (unit: Unit): AppThunk<Promise<Unit>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    let response;
    if (unit.id === undefined) {
      // Store new unit
      response = await apiClient.post<Unit>(apiEndpoint, unit);
      dispatch(addUnit(response.data));
    } else {
      // Update existing unit
      response = await apiClient.put<Unit>(`${apiEndpoint}/${unit.id}`, unit);
      dispatch(updateUnit(response.data));
    }

    dispatch(addSuccessMessage(`Eenheid '${unit.name}' is opgeslagen!`));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het opslaan van de eenheid.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const deleteUnit = (unitId: string): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());
  try {
    await apiClient.delete(`${apiEndpoint}/${unitId}`);
    dispatch(removeUnit(unitId));
    dispatch(addSuccessMessage('De eenheid is verwijderd.'));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het verwijderen van de eenheid ${unitId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

import React from 'react';
import { RouteObject } from 'react-router-dom';
import { RegionCreate } from '../../views/settings/regions/Create';
import { RegionDelete } from '../../views/settings/regions/Delete';
import { RegionDetails } from '../../views/settings/regions/Details';
import { RegionEdit } from '../../views/settings/regions/Edit';
import { RegionSettings } from '../../views/settings/regions/Regions';

export const RegionRoutes: RouteObject[] = [
  {
    path: 'regions',
    element: <RegionSettings />,
  },
  {
    path: 'regions/create',
    element: <RegionCreate />,
  },
  {
    path: 'regions/:regionId',
    element: <RegionDetails />
  },
  {
    path: 'regions/:regionId/edit',
    element: <RegionEdit />
  },
  {
    path: 'regions/:regionId/delete',
    element: <RegionDelete />
  },
];

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchStatuses } from '../features/status/statusAPI';
import { selectStatuses } from '../features/status/statusSlice';

export const useStatuses = () => {
  const dispatch = useAppDispatch();
  const statuses = useAppSelector(selectStatuses);

  useEffect(() => {
    if (statuses !== null) {
      return;
    }

    dispatch(fetchStatuses());
  }, [statuses, dispatch]);

  return statuses;
};

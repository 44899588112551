import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Message } from '../../types/message';
import { User } from '../../types/user';

export interface BaseState {
  loadingRequests: number;
  messages: Message[];
  user: User | null;
  finishedPreloaders: number;
}

const initialState: BaseState = {
  loadingRequests: 0,
  messages: [],
  user: null,
  finishedPreloaders: 0,
};

export const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    appIsLoading: (state) => {
      state.loadingRequests += 1;
    },
    appFinishedLoading: (state) => {
      state.loadingRequests -= 1;
      if (state.loadingRequests < 0) {
        console.warn('loadingRequests property in baseSlice passed below 0, the amount of appIsLoading calls is not matching the amount of appFinishedLoading calls!');
        state.loadingRequests = 0;
      }
    },
    addMessage: (state, action: PayloadAction<Message>) => {
      state.messages.push(action.payload);
    },
    removeMessage: (state, action: PayloadAction<Message>) => {
      const index = state.messages.findIndex((message) => message.id === action.payload.id);
      if (index < 0) {
        return;
      }
      state.messages.splice(index, 1);
    },
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    addFinishedPreloader: (state) => {
      state.finishedPreloaders++;
    },
  },
});

export const selectIsAppLoading = (state: RootState) => state.base.loadingRequests > 0;
export const selectMessages = (state: RootState) => state.base.messages;
export const selectUser = (state: RootState) => state.base.user;
export const selectFinishedPreloaders = (state: RootState) => state.base.finishedPreloaders;

export const { appIsLoading, appFinishedLoading, addMessage, removeMessage, setUser, addFinishedPreloader } = baseSlice.actions;

export default baseSlice.reducer;

import React from 'react';
import { Column } from './Table';

type Props<T> = {
  columns: Column<T>[];
  data: (T & { id?: string })[];
  striped?: boolean;
  hover?: boolean;
  onRowClick?: (clickedObject: T) => void;
}

export function TableBody<T>({ columns, data, striped = false, hover = false, onRowClick }: Props<T>) {
  return (
    <tbody>
      {data.map((object, index) => (
        <tr
          key={object.id}
          className={`${striped && index % 2 === 0 ? ' bg-gray-500' : ''}${hover ? ' hover:bg-gray-400' : ''}${onRowClick ? ' cursor-pointer' : ''}`}
          onClick={() => onRowClick?.(object)}
        >
          {columns.map((column) => (
            <td key={column.label}>{column.getValue(object)}</td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}

import React from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { MaterialForm } from '../../../components/materials/MaterialForm';
import { Header } from '../../../components/typography/Header';
import { storeMaterial } from '../../../features/material/materialAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Material } from '../../../types/material';

export const MaterialCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (material: Material) => {
    dispatch(storeMaterial(material));
    navigate('/settings/materials');
  }, [dispatch, navigate]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Materiaal aanmaken</Header>
      <MaterialForm onSave={onSave} onCancel={onCancel} />
    </div>
  );
};

import React from 'react';
import { useMemo } from 'react';
import { useStatuses } from '../../../hooks/useStatuses';
import { Status } from '../../../types/status';
import { convertEntityToOption } from './filter.helper';
import { FilterProps } from './filter.types';
import { FilterSelect } from './FilterSelect';

export function StatusFilter<T extends {status: Status | null}> ({ filter, onChange }: FilterProps<T>) {
  const statuses = useStatuses();
  const statusOptions = useMemo(() => statuses?.map(convertEntityToOption) || [], [statuses]);
  const selectedStatusOption = useMemo(() => statusOptions.find((status) => status.value === filter.status?.id), [statusOptions, filter]);

  return (
    <FilterSelect
      onChange={(value) => onChange({ ...filter, status: statuses?.find((c) => c.id === value?.value) || null })}
      selectedOption={selectedStatusOption}
      options={statusOptions}
      placeholder="Selecteer een status..."
    />
  );
}

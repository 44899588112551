import React from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { GroupForm } from '../../../components/groups/GroupForm';
import { Header } from '../../../components/typography/Header';
import { storeGroup } from '../../../features/group/groupAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Group } from '../../../types/group';

export const GroupCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (group: Group) => {
    dispatch(storeGroup(group));
    navigate('/settings/groups');
  }, [dispatch, navigate]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Groep aanmaken</Header>
      <GroupForm onSave={onSave} onCancel={onCancel} />
    </div>
  );
};

import React from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { Header } from '../../../components/typography/Header';
import { UnitForm } from '../../../components/units/UnitForm';
import { storeUnit } from '../../../features/unit/unitAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Unit } from '../../../types/unit';

export const UnitCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (unit: Unit) => {
    dispatch(storeUnit(unit));
    navigate('/settings/units');
  }, [dispatch, navigate]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Eenheid aanmaken</Header>
      <UnitForm onSave={onSave} onCancel={onCancel} />
    </div>
  );
};

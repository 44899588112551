import { AppThunk } from '../../app/store';
import apiClient from '../../services/apiClient';
import { Grade } from '../../types/grade';
import { addErrorMessage, addSuccessMessage } from '../base/baseAPI';
import { appFinishedLoading, appIsLoading } from '../base/baseSlice';
import { addGrade, gradesLoaded, removeGrade, selectGrade, updateGrade } from './gradeSlice';

const apiEndpoint = '/grades';

export const fetchGrades = (): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<Grade[]>(apiEndpoint);
    dispatch(gradesLoaded(response.data));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de kwaliteiten.'));
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const fetchGrade = (gradeId: string): AppThunk<Promise<Grade>> => async (dispatch, getState) => {
  const grade = selectGrade(getState(), gradeId);
  if (grade !== null) {
    return grade;
  }

  dispatch(appIsLoading());
  try {
    const response = await apiClient.get<Grade>(`${apiEndpoint}/${gradeId}`);
    dispatch(addGrade(response.data));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het laden van de kwaliteit ${gradeId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const storeGrade = (grade: Grade): AppThunk<Promise<Grade>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    let response;
    if (grade.id === undefined) {
      // Store new grade
      response = await apiClient.post<Grade>(apiEndpoint, grade);
      dispatch(addGrade(response.data));
    } else {
      // Update existing grade
      response = await apiClient.put<Grade>(`${apiEndpoint}/${grade.id}`, grade);
      dispatch(updateGrade(response.data));
    }

    dispatch(addSuccessMessage(`Kwaliteit '${grade.name}' is opgeslagen!`));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het opslaan van de kwaliteit.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const deleteGrade = (gradeId: string): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());
  try {
    await apiClient.delete(`${apiEndpoint}/${gradeId}`);
    dispatch(removeGrade(gradeId));
    dispatch(addSuccessMessage('De kwaliteit is verwijderd.'));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het verwijderen van de kwaliteit ${gradeId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

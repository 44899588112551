import React from 'react';
import { useMemo } from 'react';
import { useUnits } from '../../../hooks/useUnits';
import { Unit } from '../../../types/unit';
import { convertEntityToOption } from './filter.helper';
import { FilterProps } from './filter.types';
import { FilterSelect } from './FilterSelect';

export function UnitFilter<T extends {unit: Unit | null}>({ filter, onChange }: FilterProps<T>) {
  const units = useUnits();
  const unitOptions = useMemo(() => units?.map(convertEntityToOption) || [], [units]);
  const selectedUnitOption = useMemo(() => unitOptions.find((unit) => unit.value === filter.unit?.id), [unitOptions, filter]);

  return (
    <FilterSelect
      onChange={(value) => onChange({ ...filter, unit: units?.find((c) => c.id === value?.value) || null })}
      selectedOption={selectedUnitOption}
      options={unitOptions}
      placeholder="Selecteer een eenheid..."
    />
  );
}

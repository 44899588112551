import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Grade } from '../../types/grade';

export interface GradeState {
  grades: Grade[] | null;
}

const initialState: GradeState = {
  grades: null,
};

export const gradeSlice = createSlice({
  name: 'grade',
  initialState,
  reducers: {
    gradesLoaded: (state, action: PayloadAction<Grade[]>) => {
      state.grades = action.payload;
    },
    addGrade: (state, action: PayloadAction<Grade>) => {
      if (state.grades === null) {
        state.grades = [];
      }
      state.grades.push(action.payload);
    },
    updateGrade: (state, action: PayloadAction<Grade>) => {
      const index = state.grades?.findIndex((r) => r.id === action.payload.id);
      if (index === -1 || index === undefined) {
        return;
      }

      state.grades?.splice(index, 1, action.payload);
    },
    removeGrade: (state, action: PayloadAction<string>) => {
      const index = state.grades?.findIndex((r) => r.id === action.payload);
      if (index === -1 || index === undefined) {
        return;
      }

      state.grades?.splice(index, 1);
    },
  },
});

export const selectGrades = (state: RootState) => state.grade.grades;
export const selectGrade = (state: RootState, gradeId: string) => state.grade.grades?.find((r) => r.id === gradeId) || null;

export const { gradesLoaded, addGrade, updateGrade, removeGrade } = gradeSlice.actions;

export default gradeSlice.reducer;

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchGroups } from '../features/group/groupAPI';
import { selectGroups } from '../features/group/groupSlice';

export const useGroups = () => {
  const dispatch = useAppDispatch();
  const groups = useAppSelector(selectGroups);

  useEffect(() => {
    if (groups !== null) {
      return;
    }

    dispatch(fetchGroups());
  }, [groups, dispatch]);

  return groups;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Seller } from '../../types/seller';

export interface SellerState {
  sellers: Seller[] | null;
}

const initialState: SellerState = {
  sellers: null,
};

export const sellerSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {
    sellersLoaded: (state, action: PayloadAction<Seller[]>) => {
      state.sellers = action.payload;
    },
    addSeller: (state, action: PayloadAction<Seller>) => {
      if (state.sellers === null) {
        state.sellers = [];
      }
      state.sellers.push(action.payload);
    },
    updateSeller: (state, action: PayloadAction<Seller>) => {
      const index = state.sellers?.findIndex((r) => r.id === action.payload.id);
      if (index === -1 || index === undefined) {
        return;
      }

      state.sellers?.splice(index, 1, action.payload);
    },
    removeSeller: (state, action: PayloadAction<string>) => {
      const index = state.sellers?.findIndex((r) => r.id === action.payload);
      if (index === -1 || index === undefined) {
        return;
      }

      state.sellers?.splice(index, 1);
    },
  },
});

export const selectSellers = (state: RootState) => state.seller.sellers;
export const selectSeller = (state: RootState, sellerId: string) => state.seller.sellers?.find((r) => r.id === sellerId) || null;

export const { sellersLoaded, addSeller, updateSeller, removeSeller } = sellerSlice.actions;

export default sellerSlice.reducer;

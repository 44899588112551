import React from 'react';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Pictures = lazy(() => import('../views/pictures/Pictures'));
const PictureDetails = lazy(() => import('../views/pictures/PictureDetails'));
const PictureDelete = lazy(() => import('../views/pictures/Delete'));

export const PictureRoutes: RouteObject[] = [
  {
    path: 'pictures',
    element: <Pictures />,
  },
  {
    path: 'pictures/:pictureId',
    element: <PictureDetails />,
  },
  {
    path: 'pictures/:pictureId/delete',
    element: <PictureDelete />,
  }
];

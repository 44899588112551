import React from 'react';
import { useMemo } from 'react';
import { useMaterials } from '../../../hooks/useMaterials';
import { Material } from '../../../types/material';
import { convertEntityToOption } from './filter.helper';
import { FilterProps } from './filter.types';
import { FilterSelect } from './FilterSelect';

export function MaterialFilter<T extends {material: Material|null}>({ filter, onChange }: FilterProps<T>) {
  const materials = useMaterials();
  const materialOptions = useMemo(() => materials?.map(convertEntityToOption) || [], [materials]);
  const selectedMaterialOption = useMemo(() => materialOptions.find((material) => material.value === filter.material?.id), [materialOptions, filter]);

  return (
    <FilterSelect
      onChange={(value) => onChange({ ...filter, material: materials?.find((c) => c.id === value?.value) || null })}
      selectedOption={selectedMaterialOption}
      options={materialOptions}
      placeholder="Selecteer een materiaal..."
    />
  );
}

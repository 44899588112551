import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout } from '../components/Layout';

export const Main = () => {
  const location = useLocation();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const _paq = window._paq = window._paq || [];
    _paq.push(['setCustomUrl', location.pathname]);
    _paq.push(['trackPageView']);
  }, [location]);

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

import React, { useState } from 'react';
import { Order } from '../../types/order';
import { TableBody } from './TableBody';
import { TableColumns } from './TableColumns';
import { TableHeader } from './TableHeader';

export type Column<T> = {
  key?: keyof T;
  label: string;
  getValue: (object: T) => string;
}

type Props<T> = {
  columns: Column<T>[];
  data?: (T & { id?: string })[] | null;
  striped?: boolean;
  hover?: boolean;
  onRowClick?: (clickedObject: T) => void;
  onHeaderClick?: (column?: keyof T) => void;
  orderBy?: keyof T;
  order?: Order;
}

export function Table<T>({ columns, data, striped, hover, onRowClick, onHeaderClick, orderBy, order }: Props<T>) {
  const [visibleColumns, setVisibleColumns] = useState(columns);

  return (
    <div className="overflow-x-auto">
      <TableColumns columns={columns} onColumnChange={setVisibleColumns} />

      <table className="w-full">
        <TableHeader columns={visibleColumns} onHeaderClick={onHeaderClick} orderBy={orderBy} order={order} />
        {data && <TableBody
          columns={visibleColumns}
          data={data}
          striped={striped}
          hover={hover}
          onRowClick={onRowClick}
        />}
      </table>
    </div>
  );
}

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchMaterials } from '../features/material/materialAPI';
import { selectMaterials } from '../features/material/materialSlice';

export const useMaterials = () => {
  const dispatch = useAppDispatch();
  const materials = useAppSelector(selectMaterials);

  useEffect(() => {
    if (materials !== null) {
      return;
    }

    dispatch(fetchMaterials());
  }, [materials, dispatch]);

  return materials;
};

import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { CountryRoutes } from './settings/CountryRoutes';
import { FormatRoutes } from './settings/FormatRoutes';
import { GradeRoutes } from './settings/GradeRoutes';
import { GroupRoutes } from './settings/GroupRoutes';
import { MaterialRoutes } from './settings/MaterialRoutes';
import { RegionRoutes } from './settings/RegionRoutes';
import { SellerRoutes } from './settings/SellerRoutes';
import { StatusRoutes } from './settings/StatusRoutes';
import { UnitRoutes } from './settings/UnitRoutes';

const Settings = lazy(() => import('../views/settings/Settings'));

export const SettingRoutes: RouteObject[] = [
  {
    path: 'settings',
    element: <Settings />,
    children: [
      ...RegionRoutes,
      ...CountryRoutes,
      ...FormatRoutes,
      ...GradeRoutes,
      ...MaterialRoutes,
      ...SellerRoutes,
      ...StatusRoutes,
      ...UnitRoutes,
      ...GroupRoutes,
    ],
  },
];

import { AppThunk } from '../../app/store';
import apiClient from '../../services/apiClient';
import { Format } from '../../types/format';
import { addErrorMessage, addSuccessMessage } from '../base/baseAPI';
import { appFinishedLoading, appIsLoading } from '../base/baseSlice';
import { addFormat, formatsLoaded, removeFormat, selectFormat, updateFormat } from './formatSlice';

const apiEndpoint = '/formats';

export const fetchFormats = (): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<Format[]>(apiEndpoint);
    dispatch(formatsLoaded(response.data));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de formaten.'));
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const fetchFormat = (formatId: string): AppThunk<Promise<Format>> => async (dispatch, getState) => {
  const format = selectFormat(getState(), formatId);
  if (format !== null) {
    return format;
  }

  dispatch(appIsLoading());
  try {
    const response = await apiClient.get<Format>(`${apiEndpoint}/${formatId}`);
    dispatch(addFormat(response.data));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het laden van het formaat ${formatId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const storeFormat = (format: Format): AppThunk<Promise<Format>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    let response;
    if (format.id === undefined) {
      // Store new format
      response = await apiClient.post<Format>(apiEndpoint, format);
      dispatch(addFormat(response.data));
    } else {
      // Update existing format
      response = await apiClient.put<Format>(`${apiEndpoint}/${format.id}`, format);
      dispatch(updateFormat(response.data));
    }

    dispatch(addSuccessMessage(`Formaat '${format.name}' is opgeslagen!`));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het opslaan van het formaat.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const deleteFormat = (formatId: string): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());
  try {
    await apiClient.delete(`${apiEndpoint}/${formatId}`);
    dispatch(removeFormat(formatId));
    dispatch(addSuccessMessage('Het formaat is verwijderd.'));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het verwijderen van het formaat ${formatId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

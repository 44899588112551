import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Column, Table } from '../../../components/table/Table';
import { Header } from '../../../components/typography/Header';
import { useCountries } from '../../../hooks/useCountries';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Country } from '../../../types/country';

const getDate = (country: Country, key: keyof Country) => dayjs(country[key]).format('DD-MM-YYYY HH:mm:ss');

const columns: Column<Country>[] = [
  { label: 'Naam', getValue: (country: Country) => country.name || '-'},
  { label: 'Aangemaakt op', getValue: (country) => getDate(country, 'created_at') },
  { label: 'Aangepast op', getValue: (country) => getDate(country, 'updated_at') },
];

export const CountrySettings = () => {
  const countries = useCountries();
  const navigate = useNavigate();

  useKeyboardShortcuts({
    onCreateItem: () => navigate('create'),
  });

  return (
    <div>
      <Header>Landen ({countries?.length || 0})</Header>
      <Button onClick={() => navigate('create')} className="mb-4">Land toevoegen</Button>

      <Table
        columns={columns}
        data={countries}
        striped
        hover
        onRowClick={(country) => navigate(`/settings/countries/${country.id}`)}
      />
    </div>
  );
};

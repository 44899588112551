import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Material } from '../../types/material';

export interface MaterialState {
  materials: Material[] | null;
}

const initialState: MaterialState = {
  materials: null,
};

export const materialSlice = createSlice({
  name: 'material',
  initialState,
  reducers: {
    materialsLoaded: (state, action: PayloadAction<Material[]>) => {
      state.materials = action.payload;
    },
    addMaterial: (state, action: PayloadAction<Material>) => {
      if (state.materials === null) {
        state.materials = [];
      }
      state.materials.push(action.payload);
    },
    updateMaterial: (state, action: PayloadAction<Material>) => {
      const index = state.materials?.findIndex((r) => r.id === action.payload.id);
      if (index === -1 || index === undefined) {
        return;
      }

      state.materials?.splice(index, 1, action.payload);
    },
    removeMaterial: (state, action: PayloadAction<string>) => {
      const index = state.materials?.findIndex((r) => r.id === action.payload);
      if (index === -1 || index === undefined) {
        return;
      }

      state.materials?.splice(index, 1);
    },
  },
});

export const selectMaterials = (state: RootState) => state.material.materials;
export const selectMaterial = (state: RootState, materialId: string) => state.material.materials?.find((r) => r.id === materialId) || null;

export const { materialsLoaded, addMaterial, updateMaterial, removeMaterial } = materialSlice.actions;

export default materialSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Country } from '../../types/country';

export interface CountryState {
  countries: Country[] | null;
}

const initialState: CountryState = {
  countries: null,
};

export const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    countriesLoaded: (state, action: PayloadAction<Country[]>) => {
      state.countries = action.payload;
    },
    addCountry: (state, action: PayloadAction<Country>) => {
      if (state.countries === null) {
        state.countries = [];
      }
      state.countries.push(action.payload);
    },
    updateCountry: (state, action: PayloadAction<Country>) => {
      const index = state.countries?.findIndex((r) => r.id === action.payload.id);
      if (index === -1 || index === undefined) {
        return;
      }

      state.countries?.splice(index, 1, action.payload);
    },
    removeCountry: (state, action: PayloadAction<string>) => {
      const index = state.countries?.findIndex((r) => r.id === action.payload);
      if (index === -1 || index === undefined) {
        return;
      }

      state.countries?.splice(index, 1);
    },
  },
});

export const selectCountries = (state: RootState) => state.country.countries;
export const selectCountry = (state: RootState, countryId: string) => state.country.countries?.find((r) => r.id === countryId) || null;

export const { countriesLoaded, addCountry, updateCountry, removeCountry } = countrySlice.actions;

export default countrySlice.reducer;

import React from 'react';
import { useEffect, useRef } from 'react';
import Select, { SelectInstance, SingleValue } from 'react-select';

type FilterSelectProps<T> = {
  onChange: (newValue: SingleValue<T>) => void;
  selectedOption: T;
  options: T[];
  placeholder: string;
};

export function FilterSelect<T = unknown>({ onChange, selectedOption, options, placeholder }: FilterSelectProps<T>) {
  const ref = useRef<SelectInstance<T> | null>(null);

  useEffect(() => {
    if (selectedOption === undefined) ref.current?.clearValue();
  }, [selectedOption]);

  return (
    <Select<T>
      className="text-black"
      onChange={onChange}
      isClearable
      defaultValue={selectedOption}
      options={options}
      ref={ref}
      placeholder={placeholder}
      onKeyDown={(event) => event.stopPropagation()}
    />
  );
}

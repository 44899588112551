import React from 'react';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Tags = lazy(() => import('../views/tags/Tags'));
const GenerateTags = lazy(() => import('../views/tags/GenerateTags'));
const ScanTag = lazy(() => import('../views/tags/ScanTag'));

export const TagRoutes: RouteObject[] = [
  {
    path: 'tags',
    element: <Tags />,
  },
  {
    path: 'tags/generate',
    element: <GenerateTags />,
  },
  {
    path: 'tags/scan',
    element: <ScanTag />,
  },
];

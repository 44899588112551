import React from 'react';
import { useMemo } from 'react';
import { useCountries } from '../../../hooks/useCountries';
import { Country } from '../../../types/country';
import { convertEntityToOption } from './filter.helper';
import { FilterProps } from './filter.types';
import { FilterSelect } from './FilterSelect';

export function CountryFilter<T extends {country: Country | null}>({ filter, onChange }: FilterProps<T>) {
  const countries = useCountries();
  const countryOptions = useMemo(() => countries?.map(convertEntityToOption) || [], [countries]);
  const selectedCountryOption = useMemo(() => countryOptions.find((country) => country.value === filter.country?.id), [countryOptions, filter]);

  return (
    <FilterSelect
      onChange={(value) => onChange({ ...filter, country: countries?.find((c) => c.id === value?.value) || null })}
      selectedOption={selectedCountryOption}
      options={countryOptions}
      placeholder="Selecteer een land..."
    />
  );
}

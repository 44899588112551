import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Group } from '../../types/group';

export interface GroupState {
  groups: Group[] | null;
}

const initialState: GroupState = {
  groups: null,
};

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    groupsLoaded: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
    },
    addGroup: (state, action: PayloadAction<Group>) => {
      if (state.groups === null) {
        state.groups = [];
      }

      state.groups.push(action.payload);
    },
    updateGroup: (state, action: PayloadAction<Group>) => {
      const index = state.groups?.findIndex((g) => g.id === action.payload.id);
      if (index === -1 || index === undefined) {
        return;
      }

      state.groups?.splice(index, 1, action.payload);
    },
    removeGroup: (state, action: PayloadAction<string>) => {
      const index = state.groups?.findIndex((g) => g.id === action.payload);
      if (index === -1 || index === undefined) {
        return;
      }

      state.groups?.splice(index, 1);
    },
  },
});

export const selectGroups = (state: RootState) => state.group.groups;
export const selectGroup = (state: RootState, groupId: string) => state.group.groups?.find((g) => g.id === groupId) || null;

export const { groupsLoaded, addGroup, updateGroup, removeGroup } = groupSlice.actions;

export default groupSlice.reducer;

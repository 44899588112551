import React from 'react';
import { HTMLProps, useMemo } from 'react';

type Props = HTMLProps<HTMLDivElement>;

export const Content = ({ children, className, ...props }: Props) => {
  const defaultClassNames = 'px-5 py-3 overflow-y-auto h-full';
  const finalClassName = useMemo(() => className ? `${defaultClassNames} ${className}` : defaultClassNames, [className]);

  return (
    <div className={finalClassName} {...props}>
      {children}
    </div>
  );
};

import { AppThunk } from '../../app/store';
import apiClient from '../../services/apiClient';
import { fileToBase64 } from '../../services/FileService';
import { PaginatedResult } from '../../types/paginated-result';
import { Picture } from '../../types/picture';
import { addErrorMessage, addSuccessMessage } from '../base/baseAPI';
import { appFinishedLoading, appIsLoading } from '../base/baseSlice';
import { fetchCoins } from '../coin/coinAPI';
import { addCoinPicture } from '../coin/coinSlice';
import { fetchGroups } from '../group/groupAPI';
import { addStampPicture } from '../stamp/stampSlice';

const apiEndpoint = '/pictures';

export const fetchPictures = (page: number): AppThunk<Promise<PaginatedResult<Picture>>> => async (dispatch) => {
  const params = new URLSearchParams();
  params.append('page', page.toString());

  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<PaginatedResult<Picture>>(`${apiEndpoint}?${params.toString()}`);
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de afbeeldingen.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const fetchPictureUrl = (pictureId: string): AppThunk<Promise<string>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<Blob>(`${apiEndpoint}/${pictureId}/file`, { responseType: 'blob', timeout: 30000 });
    return URL.createObjectURL(response.data);
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de afbeelding.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const fetchPictureUrlWithoutBackground = (pictureId: string): AppThunk<Promise<string>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<Blob>(`${apiEndpoint}/${pictureId}/file/remove-background`, { responseType: 'blob', timeout: 60000 });
    return URL.createObjectURL(response.data);
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de afbeelding.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const fetchPicture = (pictureId: string): AppThunk<Promise<Picture>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<Picture>(`${apiEndpoint}/${pictureId}`);
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de afbeelding.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const uploadPicture = (picture: File, order: number, coinId?: string, stampId?: string): AppThunk<Promise<Picture>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const data = await fileToBase64(picture);
    const image = data.split('base64,')[1];
    const response = await apiClient.post<Picture>(`${apiEndpoint}`, { picture: image, coinId, stampId, order }, { timeout: 60000 });
    response.data.pivot = { order };

    if (coinId) {
      dispatch(addCoinPicture({picture: response.data, coinId}));
    }
    if (stampId) {
      dispatch(addStampPicture({picture: response.data, stampId}));
    }

    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het uploaden van de afbeelding.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const updatePictureFile = (picture: Picture, file: Blob): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const data = await fileToBase64(file);
    const image = data.split('base64,')[1];
    await apiClient.put<Picture>(`${apiEndpoint}/${picture.id}`, { picture: image, coinId: picture.coinId, order: picture.pivot?.order }, { timeout: 60000 });
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het uploaden van de afbeelding.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const updatePictureOrder = (pictureId: string, order: number): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    await apiClient.put(`${apiEndpoint}/${pictureId}`, { order });
    dispatch(addSuccessMessage('De volgorde van de afbeelding is opgeslagen.'));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het opslaan van de afbeelding volgorde.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const deletePicture = (pictureId: string): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    await apiClient.delete(`${apiEndpoint}/${pictureId}`);
    await Promise.all([
      dispatch(fetchCoins()),
      dispatch(fetchGroups()),
    ]);
    dispatch(addSuccessMessage('De afbeelding is verwijderd.'));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het verwijderen van de afbeelding.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

import React from 'react';
import { useMemo } from 'react';
import { useSellers } from '../../../hooks/useSellers';
import { Seller } from '../../../types/seller';
import { convertEntityToOption } from './filter.helper';
import { FilterProps } from './filter.types';
import { FilterSelect } from './FilterSelect';

export function SellerFilter<T extends {seller: Seller|null}>({ filter, onChange }: FilterProps<T>) {
  const sellers = useSellers();
  const sellerOptions = useMemo(() => sellers?.map(convertEntityToOption) || [], [sellers]);
  const selectedSellerOption = useMemo(() => sellerOptions.find((seller) => seller.value === filter.seller?.id), [sellerOptions, filter]);

  return (
    <FilterSelect
      onChange={(value) => onChange({ ...filter, seller: sellers?.find((c) => c.id === value?.value) || null })}
      selectedOption={selectedSellerOption}
      options={sellerOptions}
      placeholder="Selecteer een verkoper..."
    />
  );
}

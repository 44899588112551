import { AppThunk } from '../../app/store';
import apiClient from '../../services/apiClient';
import { Seller } from '../../types/seller';
import { addErrorMessage, addSuccessMessage } from '../base/baseAPI';
import { appFinishedLoading, appIsLoading } from '../base/baseSlice';
import { addSeller, removeSeller, selectSeller, sellersLoaded, updateSeller } from './sellerSlice';

const apiEndpoint = '/sellers';

export const fetchSellers = (): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<Seller[]>(apiEndpoint);
    dispatch(sellersLoaded(response.data));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de verkopers.'));
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const fetchSeller = (sellerId: string): AppThunk<Promise<Seller>> => async (dispatch, getState) => {
  const seller = selectSeller(getState(), sellerId);
  if (seller !== null) {
    return seller;
  }

  dispatch(appIsLoading());
  try {
    const response = await apiClient.get<Seller>(`${apiEndpoint}/${sellerId}`);
    dispatch(addSeller(response.data));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het laden van de verkoper ${sellerId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const storeSeller = (seller: Seller): AppThunk<Promise<Seller>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    let response;
    if (seller.id === undefined) {
      // Store new seller
      response = await apiClient.post<Seller>(apiEndpoint, seller);
      dispatch(addSeller(response.data));
    } else {
      // Update existing seller
      response = await apiClient.put<Seller>(`${apiEndpoint}/${seller.id}`, seller);
      dispatch(updateSeller(response.data));
    }

    dispatch(addSuccessMessage(`Verkoper '${seller.name}' is opgeslagen!`));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het opslaan van de verkoper.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const deleteSeller = (sellerId: string): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());
  try {
    await apiClient.delete(`${apiEndpoint}/${sellerId}`);
    dispatch(removeSeller(sellerId));
    dispatch(addSuccessMessage('De verkoper is verwijderd.'));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het verwijderen van de verkoper ${sellerId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchRegions } from '../features/region/regionAPI';
import { selectRegions } from '../features/region/regionSlice';

export const useRegions = () => {
  const dispatch = useAppDispatch();
  const regions = useAppSelector(selectRegions);

  useEffect(() => {
    if (regions !== null) {
      return;
    }

    dispatch(fetchRegions());
  }, [regions, dispatch]);

  return regions;
};

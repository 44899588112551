import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface PictureState {
  page: number;
}

const initialState: PictureState = {
  page: 1,
};

export const pictureSlice = createSlice({
  name: 'picture',
  initialState,
  reducers: {
    setPicturePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const selectPicturePage = (state: RootState) => state.picture.page;

export const { setPicturePage } = pictureSlice.actions;

export default pictureSlice.reducer;

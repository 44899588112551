import React from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { CountryForm } from '../../../components/countries/CountryForm';
import { Header } from '../../../components/typography/Header';
import { storeCountry } from '../../../features/country/countryAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Country } from '../../../types/country';

export const CountryCreate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (country: Country) => {
    dispatch(storeCountry(country));
    navigate('/settings/countries');
  }, [dispatch, navigate]);

  useKeyboardShortcuts({onCancel});

  return (
    <div>
      <Header>Land aanmaken</Header>
      <CountryForm onSave={onSave} onCancel={onCancel} />
    </div>
  );
};

import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { Header } from '../../../components/typography/Header';
import { UnitForm } from '../../../components/units/UnitForm';
import { storeUnit } from '../../../features/unit/unitAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { useUnit } from '../../../hooks/useUnit';
import { Unit } from '../../../types/unit';

export const UnitEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { unitId } = useParams();
  const { unit } = useUnit(unitId);

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (unit: Unit) => {
    dispatch(storeUnit(unit));
    navigate(`/settings/units/${unitId}`);
  }, [dispatch, navigate, unitId]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Eenheid aanpassen</Header>
      {unit && <UnitForm onSave={onSave} onCancel={onCancel} unit={unit} />}
    </div>
  );
};

import React from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Column, Table } from '../../../components/table/Table';
import { Header } from '../../../components/typography/Header';
import { useGroups } from '../../../hooks/useGroups';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Group } from '../../../types/group';

const columns: Column<Group>[] = [
  { label: 'Naam', getValue: (group: Group) => group.name || '-'},
  { label: 'Aangemaakt op', getValue: (group) => dayjs(group['created_at']).format('DD-MM-YYYY HH:mm:ss') },
  { label: 'Aangepast op', getValue: (group) => dayjs(group['updated_at']).format('DD-MM-YYYY HH:mm:ss') },
];

export const GroupSettings = () => {
  const groups = useGroups();
  const navigate = useNavigate();

  useKeyboardShortcuts({
    onCreateItem: () => navigate('create'),
  });

  return (
    <div>
      <Header>Groepen ({groups?.length || 0})</Header>
      <Button onClick={() => navigate('create')} className="mb-4">Groep toevoegen</Button>

      <Table
        columns={columns}
        data={groups}
        striped
        hover
        onRowClick={(group) => navigate(`/settings/groups/${group.id}`)}
      />
    </div>
  );
};

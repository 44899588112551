import sha1 from 'crypto-js/sha1';
import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../Button';
import { Dropdown } from '../Dropdown';
import { Column } from './Table';

type Props<T> = {
  columns: Column<T>[];
  onColumnChange: (columns: Column<T>[]) => void;
}

export function TableColumns<T>({ columns, onColumnChange }: Props<T>) {
  const columnHash = useMemo(() => sha1(JSON.stringify(columns)).toString(), [columns]);
  const [visibleColumns, setVisibleColumns] = useState(columns);

  useEffect(() => {
    const storedColumns = localStorage.getItem(`table-${columnHash}`);
    if (storedColumns === null) {
      return;
    }

    setVisibleColumns(JSON.parse(storedColumns));
  }, [columnHash]);

  useEffect(() => {
    localStorage.setItem(`table-${columnHash}`, JSON.stringify(visibleColumns));
  }, [columnHash, visibleColumns]);


  const handleColumnChange: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    const column = columns.find((c) => c.label === event.target.value);
    if (column === undefined) {
      return;
    }

    let newColumns = [];
    if (event.target.checked) {
      newColumns = [...visibleColumns, column];
    } else {
      newColumns = visibleColumns.filter((c) => c.label !== column.label);
    }

    setVisibleColumns(newColumns);
  }, [visibleColumns, columns]);

  // Maintain column order as listed in `columns`
  const sortedColumns = useMemo(() => columns.filter((c) => visibleColumns.find((col) => c.label === col.label) !== undefined), [columns, visibleColumns]);

  useEffect(() => {
    onColumnChange(sortedColumns);
  }, [sortedColumns, onColumnChange]);

  return (
    <div className="text-right flex flex-row gap-1 justify-end">
      <Dropdown title="Kolommen">
        <div>
          {columns.map((column) => (
            <div key={column.label}>
              <input
                type="checkbox"
                checked={visibleColumns.find((c) => c.label === column.label) !== undefined}
                value={column.label}
                onChange={handleColumnChange}
              />
              <label className="ml-2">{column.label}</label>
            </div>
          ))}
        </div>
      </Dropdown>
      <Button onClick={() => setVisibleColumns(columns)}>Reset</Button>
    </div>
  );
}

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchSeller } from '../features/seller/sellerAPI';

export const useSeller = (sellerId: string | undefined) => {
  const dispatch = useAppDispatch();
  const seller = useAppSelector((state) => state.seller.sellers?.find((r) => r.id === sellerId));

  useEffect(() => {
    if (!sellerId) {
      return;
    }

    dispatch(fetchSeller(sellerId));
  }, [sellerId, dispatch]);

  return { seller };
};

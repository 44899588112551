import React from 'react';
import { Link } from 'react-router-dom';
import { Content } from '../components/Content';
import { Layout } from '../components/Layout';

export const PageNotFound = () => (
  <Layout>
    <Content>
      <p>Pagina niet gevonden.</p>
      <p><Link to="/">Terug naar homepagina.</Link></p>
    </Content>
  </Layout>
);

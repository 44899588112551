import React from 'react';
import { ReactNode } from 'react';

type Props = {
  type?: 'button' | 'submit';
  onClick?: () => void | Promise<void>;
  children: ReactNode;
  disabled?: boolean;
  primary?: boolean;
  className?: string;
}

export const Button = ({ type = 'button', children, onClick, disabled = false, primary = false, className}: Props) => (
  <button
    type={type}
    onClick={onClick}
    disabled={disabled}
    className={`${primary ? 'bg-gray-800': ''} hover:bg-gray-700${disabled ? ' text-gray-400 hover:bg-inherit cursor-not-allowed' : ''} btn px-3 py-1 rounded-lg border-2 border-gray-800 shadow-lg ${(className || '')}`}
  >
    {children}
  </button>
);

import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { Alert } from '../../../components/Alert';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/button-group/ButtonGroup';
import { Header } from '../../../components/typography/Header';
import { deleteSeller } from '../../../features/seller/sellerAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { useSeller } from '../../../hooks/useSeller';

export const SellerDelete = () => {
  const { sellerId } = useParams();
  const { seller } = useSeller(sellerId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    if (!sellerId) {
      return;
    }

    dispatch(deleteSeller(sellerId));
    navigate('/settings/sellers');
  }, [sellerId, dispatch, navigate]);

  useKeyboardShortcuts({
    onCancel: () => navigate(-1),
  });

  return (
    <div>
      <Header>Verkoper verwijderen</Header>
      <Alert type="error">
        <p>Weet je zeker dat je &apos;{seller?.name}&apos; wilt verwijderen?</p>

        <div className="mt-3">
          <ButtonGroup>
            <Button onClick={() => navigate(-1)}>Annuleren</Button>
            <Button onClick={handleDelete}>Verwijderen</Button>
          </ButtonGroup>
        </div>
      </Alert>
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Unit } from '../../types/unit';

export interface UnitState {
  units: Unit[] | null;
}

const initialState: UnitState = {
  units: null,
};

export const unitSlice = createSlice({
  name: 'unit',
  initialState,
  reducers: {
    unitsLoaded: (state, action: PayloadAction<Unit[]>) => {
      state.units = action.payload;
    },
    addUnit: (state, action: PayloadAction<Unit>) => {
      if (state.units === null) {
        state.units = [];
      }
      state.units.push(action.payload);
    },
    updateUnit: (state, action: PayloadAction<Unit>) => {
      const index = state.units?.findIndex((r) => r.id === action.payload.id);
      if (index === -1 || index === undefined) {
        return;
      }

      state.units?.splice(index, 1, action.payload);
    },
    removeUnit: (state, action: PayloadAction<string>) => {
      const index = state.units?.findIndex((r) => r.id === action.payload);
      if (index === -1 || index === undefined) {
        return;
      }

      state.units?.splice(index, 1);
    },
  },
});

export const selectUnits = (state: RootState) => state.unit.units;
export const selectUnit = (state: RootState, unitId: string) => state.unit.units?.find((r) => r.id === unitId) || null;

export const { unitsLoaded, addUnit, updateUnit, removeUnit } = unitSlice.actions;

export default unitSlice.reducer;

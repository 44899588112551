import React from 'react';
import { Order } from '../../types/order';
import { Column } from './Table';

type Props<T> = {
  columns: Column<T>[];
  onHeaderClick?: (column?: keyof T) => void;
  orderBy?: keyof T;
  order?: Order;
}

export function TableHeader<T>({ columns, onHeaderClick, orderBy, order }: Props<T>) {
  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <th
            key={column.label}
            className={`text-left${onHeaderClick !== undefined ? ' cursor-pointer' : ''}`}
            onClick={() => onHeaderClick?.(column.key)}
          >
            {column.label}
            {orderBy !== undefined && orderBy === column.key && order !== undefined && (
              order === 'asc' ? <span className="ml-2">&uarr;</span> : <span className="ml-2">&darr;</span>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
}

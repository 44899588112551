import React from 'react';
import { RouteObject } from 'react-router-dom';
import { UnitCreate } from '../../views/settings/units/Create';
import { UnitDelete } from '../../views/settings/units/Delete';
import { UnitDetails } from '../../views/settings/units/Details';
import { UnitEdit } from '../../views/settings/units/Edit';
import { UnitSettings } from '../../views/settings/units/Units';

export const UnitRoutes: RouteObject[] = [
  {
    path: 'units',
    element: <UnitSettings />,
  },
  {
    path: 'units/create',
    element: <UnitCreate />,
  },
  {
    path: 'units/:unitId',
    element: <UnitDetails />,
  },
  {
    path: 'units/:unitId/edit',
    element: <UnitEdit />,
  },
  {
    path: 'units/:unitId/delete',
    element: <UnitDelete />,
  },
];

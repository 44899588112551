import React, { ReactNode, useState } from 'react';
import { useKeyboardShortcuts } from '../hooks/useKeyboardShortcuts';
import { Button } from './Button';

type Props = {
  title: string;
  children: ReactNode;
}

export const Dropdown = ({ title, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useKeyboardShortcuts({
    onCancel: () => setIsOpen(false),
  });

  return (
    <div>
      <div className="relative inline-block">
        <Button onClick={() => setIsOpen(!isOpen)} className={isOpen ? 'rounded-none rounded-t-lg' : 'rounded-lg'}>{title}</Button>
        <div className={`${isOpen ? 'block' : 'hidden'} absolute bg-gray-700 px-3 py-1 text-left rounded-lg rounded-tr-none border-2 border-gray-800 w-[200px] mt-[-2px] right-0`}>
          {children}
        </div>
      </div>
    </div>
  );
};

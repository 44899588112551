import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { GroupForm } from '../../../components/groups/GroupForm';
import { Header } from '../../../components/typography/Header';
import { storeGroup } from '../../../features/group/groupAPI';
import { useGroup } from '../../../hooks/useGroup';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { Group } from '../../../types/group';

export const GroupEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { groupId } = useParams();
  const { group } = useGroup(groupId);

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (group: Group) => {
    dispatch(storeGroup(group));
    navigate(`/settings/groups/${groupId}`);
  }, [dispatch, navigate, groupId]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Groep aanpassen</Header>
      {group && <GroupForm onSave={onSave} onCancel={onCancel} group={group} />}
    </div>
  );
};

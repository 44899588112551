import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchUnit } from '../features/unit/unitAPI';

export const useUnit = (unitId: string | undefined) => {
  const dispatch = useAppDispatch();
  const unit = useAppSelector((state) => state.unit.units?.find((r) => r.id === unitId));

  useEffect(() => {
    if (!unitId) {
      return;
    }

    dispatch(fetchUnit(unitId));
  }, [unitId, dispatch]);

  return { unit };
};

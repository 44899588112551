import { AppThunk } from '../../app/store';
import { Message, MessageType } from '../../types/message';
import { addMessage, removeMessage } from './baseSlice';
import { v4 as uuid } from 'uuid';

export function addSuccessMessage(message: string, timeout = 5000): AppThunk {
  return addGenericMessage(message, timeout, 'success');
}

export function addInfoMessage(message: string, timeout = 5000): AppThunk {
  return addGenericMessage(message, timeout, 'info');
}

export function addErrorMessage(message: string, timeout = 10000): AppThunk {
  return addGenericMessage(message, timeout, 'error');
}

const addGenericMessage = (message: string, timeout: number, type: MessageType): AppThunk => {
  return (dispatch) => {
    const msg: Message = { id: uuid(), type, message };
    dispatch(addMessage(msg));
    setTimeout(() => {
      dispatch(removeMessage(msg));
    }, timeout);
  };
};

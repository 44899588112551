import React from 'react';
import { useMemo } from 'react';
import { FilterProps, NumericOption } from './filter.types';
import { FilterSelect } from './FilterSelect';

const convertYearToOption = (year?: number | null): NumericOption => ({ label: year || 0, value: year || 0 });

export function YearFilter<T extends {year: number | null}>({ filter, onChange, years }: FilterProps<T> & {years: (number|null|undefined)[]}) {
  const yearOptions = useMemo(() => years.map(convertYearToOption) || [], [years]);
  const selectedYearOption = useMemo(() => yearOptions.find((year) => year.value === filter.year), [yearOptions, filter]);

  return (
    <FilterSelect
      onChange={(value) => onChange({ ...filter, year: value?.value || null })}
      selectedOption={selectedYearOption}
      options={yearOptions}
      placeholder="Selecteer een jaar..."
    />
  );
}

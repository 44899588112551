import React from 'react';
import { RouteObject } from 'react-router-dom';
import { FormatCreate } from '../../views/settings/formats/Create';
import { FormatDelete } from '../../views/settings/formats/Delete';
import { FormatDetails } from '../../views/settings/formats/Details';
import { FormatEdit } from '../../views/settings/formats/Edit';
import { FormatSettings } from '../../views/settings/formats/Formats';

export const FormatRoutes: RouteObject[] = [
  {
    path: 'formats',
    element: <FormatSettings />,
  },
  {
    path: 'formats/create',
    element: <FormatCreate />,
  },
  {
    path: 'formats/:formatId',
    element: <FormatDetails />
  },
  {
    path: 'formats/:formatId/edit',
    element: <FormatEdit />
  },
  {
    path: 'formats/:formatId/delete',
    element: <FormatDelete />
  },
];

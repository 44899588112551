import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { RegionForm } from '../../../components/regions/RegionForm';
import { Header } from '../../../components/typography/Header';
import { storeRegion } from '../../../features/region/regionAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { useRegion } from '../../../hooks/useRegion';
import { Region } from '../../../types/region';

export const RegionEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { regionId } = useParams();
  const { region } = useRegion(regionId);

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(async (region: Region) => {
    dispatch(storeRegion(region));
    navigate(`/settings/regions/${regionId}`);
  }, [dispatch, navigate, regionId]);

  useKeyboardShortcuts({ onCancel });

  return (
    <div>
      <Header>Regio aanpassen</Header>
      {region && <RegionForm onSave={onSave} onCancel={onCancel} region={region} />}
    </div>
  );
};

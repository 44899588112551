import { useEffect } from 'react';

type Props = {
  onFirstItem?: () => void;
  onLastItem?: () => void;
  onNextItem?: () => void;
  onPrevItem?: () => void;
  onOpenItem?: () => void;
  onEditItem?: () => void;
  onDeleteItem?: () => void;
  onCreateItem?: () => void;
  onCancel?: () => void;
  onKey?: (key: string, event: KeyboardEvent) => void;
}

export const useKeyboardShortcuts = ({
  onFirstItem,
  onLastItem,
  onNextItem,
  onPrevItem,
  onOpenItem,
  onEditItem,
  onDeleteItem,
  onCreateItem,
  onCancel,
  onKey,
}: Props) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.isComposing || event.keyCode === 229 || event.target instanceof HTMLInputElement) {
        // see: https://developer.mozilla.org/en-US/docs/Web/API/Element/keydown_event
        return;
      }

      if ((event.key === 'Home' || (event.key === 'ArrowUp' && event.metaKey)) && onFirstItem) {
        event.preventDefault();
        onFirstItem();
        return;
      }

      if ((event.key === 'End' || (event.key === 'ArrowDown' && event.metaKey)) && onLastItem) {
        event.preventDefault();
        onLastItem();
        return;
      }

      if (event.key === 'ArrowDown' && onNextItem) {
        event.preventDefault();
        onNextItem();
        return;
      }

      if (event.key === 'ArrowUp' && onPrevItem) {
        event.preventDefault();
        onPrevItem();
        return;
      }

      if (event.key === 'Enter' && onOpenItem) {
        event.preventDefault();
        onOpenItem();
        return;
      }

      if ((event.key === 'Delete' || event.key === 'd') && onDeleteItem) {
        event.preventDefault();
        onDeleteItem();
        return;
      }

      if (event.key === 'e' && onEditItem) {
        event.preventDefault();
        onEditItem();
        return;
      }

      if (event.key === 'Escape' && onCancel) {
        event.preventDefault();
        onCancel();
        return;
      }

      if (event.key === 'c' && onCreateItem) {
        event.preventDefault();
        onCreateItem();
        return;
      }

      onKey?.(event.key, event);
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [onFirstItem, onLastItem, onNextItem, onPrevItem, onOpenItem, onEditItem, onDeleteItem, onCreateItem, onCancel, onKey]);
};

import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../app/hooks';
import { Loader } from '../components/Loader';
import { setUser } from '../features/base/baseSlice';
import AuthenticationService from '../services/AuthenticationService';

type Props = {
  children: JSX.Element;
};

export const AuthenticationProvider = ({ children }: Props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const authenticationService = useMemo(() => AuthenticationService, []);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    (async () => {
      // Request token using code and state from querystring
      const params = new URLSearchParams(window.location.search);
      const paramCode = params.get('code');
      const paramState = params.get('state');
      if (paramCode !== null && paramState !== null) {
        await authenticationService.getTokensFromCode(paramCode, paramState);
        window.location.href = '/';
        return;
      }

      const user = await authenticationService.getUser();
      dispatch(setUser(user));

      setIsAuthenticated(true);
    })();
  }, [isAuthenticated, authenticationService, dispatch]);

  if (!isAuthenticated) {
    return <Loader />;
  }

  return children;
};

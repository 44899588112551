import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchRegion } from '../features/region/regionAPI';

export const useRegion = (regionId: string | undefined) => {
  const dispatch = useAppDispatch();
  const region = useAppSelector((state) => state.region.regions?.find((r) => r.id === regionId));

  useEffect(() => {
    if (!regionId) {
      return;
    }

    dispatch(fetchRegion(regionId));
  }, [regionId, dispatch]);

  return { region };
};

import React from 'react';
import { RouteObject } from 'react-router-dom';
import { CountrySettings } from '../../views/settings/countries/Countries';
import { CountryCreate } from '../../views/settings/countries/Create';
import { CountryDelete } from '../../views/settings/countries/Delete';
import { CountryDetails } from '../../views/settings/countries/Details';
import { CountryEdit } from '../../views/settings/countries/Edit';

export const CountryRoutes: RouteObject[] = [
  {
    path: 'countries',
    element: <CountrySettings />,
  },
  {
    path: 'countries/create',
    element: <CountryCreate />,
  },
  {
    path: 'countries/:countryId',
    element: <CountryDetails />
  },
  {
    path: 'countries/:countryId/edit',
    element: <CountryEdit />
  },
  {
    path: 'countries/:countryId/delete',
    element: <CountryDelete />
  },
];

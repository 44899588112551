import React from 'react';
import { RouteObject } from 'react-router-dom';
import { StatusCreate } from '../../views/settings/statuses/Create';
import { StatusDelete } from '../../views/settings/statuses/Delete';
import { StatusDetails } from '../../views/settings/statuses/Details';
import { StatusEdit } from '../../views/settings/statuses/Edit';
import { StatusSettings } from '../../views/settings/statuses/Statuses';

export const StatusRoutes: RouteObject[] = [
  {
    path: 'statuses',
    element: <StatusSettings />,
  },
  {
    path: 'statuses/create',
    element: <StatusCreate />,
  },
  {
    path: 'statuses/:statusId',
    element: <StatusDetails />,
  },
  {
    path: 'statuses/:statusId/edit',
    element: <StatusEdit />,
  },
  {
    path: 'statuses/:statusId/delete',
    element: <StatusDelete />,
  },
];

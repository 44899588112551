import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { GroupRoutes } from './routes/GroupRoutes';
import { PictureRoutes } from './routes/PictureRoutes';
import { SettingRoutes } from './routes/SettingRoutes';
import { StampRoutes } from './routes/StampRoutes';
import { SummaryRoutes } from './routes/SummaryRoutes';
import { TagRoutes } from './routes/TagRoutes';
import { Main } from './views/Main';
import { PageNotFound } from './views/PageNotFound';
import { CoinRoutes } from './routes/CoinRoutes';

const routes = [
  {
    path: '/',
    element: <Main />,
    errorElement: <PageNotFound />,
    children: [
      ...SettingRoutes,
      ...CoinRoutes,
      ...PictureRoutes,
      ...GroupRoutes,
      ...SummaryRoutes,
      ...TagRoutes,
      ...StampRoutes,
    ],
  },
];

const router = createBrowserRouter(routes);

export const Router = () => <RouterProvider router={router} />;

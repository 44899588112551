import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { Alert } from '../../../components/Alert';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/button-group/ButtonGroup';
import { Header } from '../../../components/typography/Header';
import { deleteGrade } from '../../../features/grade/gradeAPI';
import { useGrade } from '../../../hooks/useGrade';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';

export const GradeDelete = () => {
  const { gradeId } = useParams();
  const { grade } = useGrade(gradeId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    if (!gradeId) {
      return;
    }

    dispatch(deleteGrade(gradeId));
    navigate('/settings/grades');
  }, [gradeId, dispatch, navigate]);

  useKeyboardShortcuts({
    onCancel: () => navigate(-1),
  });

  return (
    <div>
      <Header>Kwaliteit verwijderen</Header>
      <Alert type="error">
        <p>Weet je zeker dat je &apos;{grade?.name}&apos; wilt verwijderen?</p>

        <div className="mt-3">
          <ButtonGroup>
            <Button onClick={() => navigate(-1)}>Annuleren</Button>
            <Button onClick={handleDelete}>Verwijderen</Button>
          </ButtonGroup>
        </div>
      </Alert>
    </div>
  );
};

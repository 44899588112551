import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchFormat } from '../features/format/formatAPI';

export const useFormat = (formatId: string | undefined) => {
  const dispatch = useAppDispatch();
  const format = useAppSelector((state) => state.format.formats?.find((r) => r.id === formatId));

  useEffect(() => {
    if (!formatId) {
      return;
    }

    dispatch(fetchFormat(formatId));
  }, [formatId, dispatch]);

  return { format };
};

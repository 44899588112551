import React from 'react';
import { ReactNode } from 'react';

type Props = {
  label?: string;
  children: ReactNode;
  required?: boolean;
}

export const FormGroup = ({ label, children, required = false }: Props) => (
  <div className="flex flex-col md:flex-row items-start md:items-center mb-3">
    {label !== undefined && (
      <label className="w-56 flex flex-row">
        {label}
        {required && <div className="rounded-full bg-red-500 w-2 h-2 ml-2" />}
      </label>
    )}
    <div className="flex-1 w-full">
      {children}
    </div>
  </div>
);

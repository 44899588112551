import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchSellers } from '../features/seller/sellerAPI';
import { selectSellers } from '../features/seller/sellerSlice';

export const useSellers = () => {
  const dispatch = useAppDispatch();
  const sellers = useAppSelector(selectSellers);

  useEffect(() => {
    if (sellers !== null) {
      return;
    }

    dispatch(fetchSellers());
  }, [sellers, dispatch]);

  return sellers;
};

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchCountries } from '../features/country/countryAPI';
import { selectCountries } from '../features/country/countrySlice';

export const useCountries = () => {
  const dispatch = useAppDispatch();
  const countries = useAppSelector(selectCountries);

  useEffect(() => {
    if (countries !== null) {
      return;
    }

    dispatch(fetchCountries());
  }, [countries, dispatch]);

  return countries;
};

import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchCountry } from '../features/country/countryAPI';

export const useCountry = (countryId: string | undefined) => {
  const dispatch = useAppDispatch();
  const country = useAppSelector((state) => state.country.countries?.find((c) => c.id === countryId));

  useEffect(() => {
    if (!countryId) {
      return;
    }

    dispatch(fetchCountry(countryId));
  }, [countryId, dispatch]);

  return { country };
};

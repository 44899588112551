import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { Alert } from '../../../components/Alert';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/button-group/ButtonGroup';
import { Header } from '../../../components/typography/Header';
import { deleteStatus } from '../../../features/status/statusAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { useStatus } from '../../../hooks/useStatus';

export const StatusDelete = () => {
  const { statusId } = useParams();
  const { status } = useStatus(statusId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    if (!statusId) {
      return;
    }

    dispatch(deleteStatus(statusId));
    navigate('/settings/statuses');
  }, [statusId, dispatch, navigate]);

  useKeyboardShortcuts({
    onCancel: () => navigate(-1),
  });

  return (
    <div>
      <Header>Status verwijderen</Header>
      <Alert type="error">
        <p>Weet je zeker dat je &apos;{status?.name}&apos; wilt verwijderen?</p>

        <div className="mt-3">
          <ButtonGroup>
            <Button onClick={() => navigate(-1)}>Annuleren</Button>
            <Button onClick={handleDelete}>Verwijderen</Button>
          </ButtonGroup>
        </div>
      </Alert>
    </div>
  );
};

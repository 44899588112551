import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { fetchFormats } from '../features/format/formatAPI';
import { selectFormats } from '../features/format/formatSlice';

export const useFormats = () => {
  const dispatch = useAppDispatch();
  const formats = useAppSelector(selectFormats);

  useEffect(() => {
    if (formats !== null) {
      return;
    }

    dispatch(fetchFormats());
  }, [formats, dispatch]);

  return formats;
};

import React from 'react';
import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Coins = lazy(() => import('../views/coins/Coins'));
const CoinDetails = lazy(() => import('../views/coins/CoinDetails'));
const CoinCreate = lazy(() => import('../views/coins/Create'));
const CoinEdit = lazy(() => import('../views/coins/Edit'));
const CoinDelete = lazy(() => import('../views/coins/Delete'));
const CoinExport = lazy(() => import('../views/coins/Export'));
const CoinLinkTag = lazy(() => import('../views/coins/LinkTag'));

export const CoinRoutes: RouteObject[] = [
  {
    path: '',
    element: <Coins />,
  },
  {
    path: 'coins/export',
    element: <CoinExport />,
  },
  {
    path: 'coins/:coinId',
    element: <CoinDetails />,
  },
  {
    path: 'coins/create',
    element: <CoinCreate />,
  },
  {
    path: 'coins/:coinId/edit',
    element: <CoinEdit />,
  },
  {
    path: 'coins/:coinId/delete',
    element: <CoinDelete />,
  },
  {
    path: 'coins/:coinId/link-tag',
    element: <CoinLinkTag />,
  },
];

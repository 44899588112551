import React from 'react';
import {
  selectStampFilter,
  selectStampNominalValues,
  selectStampYears,
  setStampFilter
} from '../../features/stamp/stampSlice';
import { StampFilter as StampFilterType } from '../../types/stamp-filter';
import { Button } from '../Button';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { NominalValueFilter } from '../coins/coin-filter/NominalValueFilter';
import { PicturesFilter } from '../coins/coin-filter/PicturesFilter';
import { StatusFilter } from '../coins/coin-filter/StatusFilter';
import { UnitFilter } from '../coins/coin-filter/UnitFilter';
import { YearFilter } from '../coins/coin-filter/YearFilter';

export const defaultFilter: StampFilterType = {
  status: null,
  year: null,
  unit: null,
  withoutPictures: false,
  nominalValue: null,
};

export const StampFilter = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectStampFilter);
  const onFilterChange = useCallback((changedFilter: StampFilterType) => dispatch(setStampFilter(changedFilter)), [dispatch]);
  const nominalValues = useAppSelector(selectStampNominalValues);
  const years = useAppSelector(selectStampYears);

  const handleResetFilter = useCallback(() => {
    dispatch(setStampFilter(defaultFilter));
  }, [dispatch]);

  return (
    <div className="flex flex-col gap-4">
      <StatusFilter filter={filter} onChange={onFilterChange} />
      <UnitFilter filter={filter} onChange={onFilterChange} />
      <YearFilter filter={filter} onChange={onFilterChange} years={years} />
      <NominalValueFilter filter={filter} onChange={onFilterChange} nominalValues={nominalValues} />
      <PicturesFilter filter={filter} onChange={onFilterChange} />

      <Button onClick={handleResetFilter}>Reset filter</Button>
    </div>
  );
};

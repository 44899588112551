import { AppThunk } from '../../app/store';
import apiClient from '../../services/apiClient';
import { Country } from '../../types/country';
import { addErrorMessage, addSuccessMessage } from '../base/baseAPI';
import { appFinishedLoading, appIsLoading } from '../base/baseSlice';
import { addCountry, countriesLoaded, removeCountry, selectCountry, updateCountry } from './countrySlice';

export const fetchCountries = (): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    const response = await apiClient.get<Country[]>('/countries');
    dispatch(countriesLoaded(response.data));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het laden van de landen.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const fetchCountry = (countryId: string): AppThunk<Promise<Country>> => async (dispatch, getState) => {
  const country = selectCountry(getState(), countryId);
  if (country !== null) {
    return country;
  }

  dispatch(appIsLoading());
  try {
    const response = await apiClient.get<Country>(`/countries/${countryId}`);
    dispatch(addCountry(response.data));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het laden van het land ${countryId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const storeCountry = (country: Country): AppThunk<Promise<Country>> => async (dispatch) => {
  dispatch(appIsLoading());

  try {
    let response;
    if (country.id === undefined) {
      // Store new country
      response = await apiClient.post<Country>('/countries', country);
      dispatch(addCountry(response.data));
    } else {
      // Update existing country
      response = await apiClient.put<Country>(`/countries/${country.id}`, country);
      dispatch(updateCountry(response.data));
    }

    dispatch(addSuccessMessage(`Land '${country.name}' is opgeslagen!`));
    return response.data;
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage('Er is een fout opgetreden tijdens het opslaan van het land.'));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

export const deleteCountry = (countryId: string): AppThunk<Promise<void>> => async (dispatch) => {
  dispatch(appIsLoading());
  try {
    await apiClient.delete(`/countries/${countryId}`);
    dispatch(removeCountry(countryId));
    dispatch(addSuccessMessage('Het land is verwijderd.'));
  } catch (e) {
    console.error(e);
    dispatch(addErrorMessage(`Er is een fout opgetreden tijdens het verwijderen van het land ${countryId}.`));
    throw e;
  } finally {
    dispatch(appFinishedLoading());
  }
};

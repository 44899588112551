import React from 'react';
import { forwardRef, InputHTMLAttributes } from 'react';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  prefix?: string;
  suffix?: string;
};

const FormInput = forwardRef<HTMLInputElement, Props>(({ prefix, suffix, ...props }, ref) => (
  <div className="flex flex-row">
    {prefix !== undefined && (
      <div className="bg-gray-800 rounded-l-lg px-3 flex flex-col items-center justify-center">
        <span>{prefix}</span>
      </div>
    )}
    <input
      {...props}
      ref={ref}
      className={`text-gray-800 ${prefix === undefined ? 'rounded-l-lg' : ''} ${suffix === undefined ? 'rounded-r-lg' : ''} px-3 py-1 block w-full bg-gray-300`}
    />
    {suffix !== undefined && (
      <div className="bg-gray-800 rounded-r-lg px-3 flex flex-col items-center justify-center">
        <span>{suffix}</span>
      </div>
    )}
  </div>
));

FormInput.displayName = 'FormInput';

export { FormInput };

import React from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { Alert } from '../../../components/Alert';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/button-group/ButtonGroup';
import { Header } from '../../../components/typography/Header';
import { deleteUnit } from '../../../features/unit/unitAPI';
import { useKeyboardShortcuts } from '../../../hooks/useKeyboardShortcuts';
import { useUnit } from '../../../hooks/useUnit';

export const UnitDelete = () => {
  const { unitId } = useParams();
  const { unit } = useUnit(unitId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    if (!unitId) {
      return;
    }

    dispatch(deleteUnit(unitId));
    navigate('/settings/units');
  }, [unitId, dispatch, navigate]);

  useKeyboardShortcuts({
    onCancel: () => navigate(-1),
  });

  return (
    <div>
      <Header>Eenheid verwijderen</Header>
      <Alert type="error">
        <p>Weet je zeker dat je &apos;{unit?.name}&apos; wilt verwijderen?</p>

        <div className="mt-3">
          <ButtonGroup>
            <Button onClick={() => navigate(-1)}>Annuleren</Button>
            <Button onClick={handleDelete}>Verwijderen</Button>
          </ButtonGroup>
        </div>
      </Alert>
    </div>
  );
};
